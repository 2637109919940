import { Box } from "@mui/material";
import { styled } from "@mui/material";

const FlexBetweenTop = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
});

export default FlexBetweenTop;
