import { Avatar, Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFriends } from "state";
import UserImage from "./UserImage";
import { useEffect, useState } from "react";

const Friend = ({ friendId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const friends = useSelector((state) => state.auth.user.friends);
  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;
  const [friend, setFriend] = useState(null);

  const isFriend = friends.find((friend) => friend._id === friendId);

  const fetchFriend = async () => {
    const data = await (
      await fetch(`${process.env.REACT_APP_SERVER_URL}/users/${friendId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
    ).json();
    setFriend(data);
  };

  const patchFriend = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/${_id}/${friendId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };

  const goToFriend = () => {
    navigate(`/profile/${friendId}`);
  };

  useEffect(() => {
    fetchFriend();
  }, []);

  return (
    <>
      {friend === null ? null : (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="left"
          gap="1rem"
        >
          {friend.picturePath === "" ? (
            <Avatar>{friend.firstName[0] + friend.lastName[0]}</Avatar>
          ) : (
            <UserImage image={friend.picturePath} size="55px" />
          )}
          <Typography
            onClick={goToFriend}
            color={main}
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {friend.firstName + " " + friend.lastName}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Friend;
