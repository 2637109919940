import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Fab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  useTheme,
  InputLabel,
  Chip,
  TableBody,
  IconButton,
  Badge,
  Backdrop,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Navbar from "scenes/navbar";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import { setErrorMessage, setErrorState } from "state/error";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import EventSmallWidget from "scenes/widgets/EventSmallWidget";

const SerienPage = () => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [isAddEvent, setAddEvent] = useState(false);
  const [seriesList, setSeriesList] = useState([]);
  const timeNow = new Date();
  const { palette } = useTheme();
  const [team, setTeam] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [eventOpen, setEventOpen] = useState(false);
  const [seriesEvents, setSeriesEvents] = useState([]);

  const handleEventOpen = async (serie) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/list`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ list: serie.events }),
      }
    );
    const data = await response.json();
    setSeriesEvents(data);
    setEventOpen(true);
  };

  const handleEventClose = () => {
    setEventOpen(false);
    setSeriesEvents([]);
  };

  const setAddEventToggle = () => {
    setAddEvent(!isAddEvent);
  };

  const getTeam = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/team`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setTeam(data);
  };

  const getSeriesList = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/series`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setSeriesList(data);
  };

  useEffect(() => {
    getTeam();
    getSeriesList();
  }, []);

  useEffect(() => {}, [seriesList]);

  const handleFormAddSeries = async (values, onSubmitProps) => {
    const addEventResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/series`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    );
    const addEventLog = await addEventResponse.json();
    if ("error" in addEventLog) {
      dispatch(setErrorState());
      dispatch(setErrorMessage({ error: addEventLog.error }));
    } else {
      onSubmitProps.resetForm();
      setSeriesList(addEventLog);
      setAddEventToggle();
    }
  };

  const initialValues = {
    name: "",
    start: timeNow,
    end: timeNow,
    description: "",
    managers: [],
    drivers: [],
  };

  const validationsSchema = yup.object().shape({
    name: yup.string().required("required"),
    description: yup.string().required("required"),
    start: yup.date().required("required"),
    end: yup.date().required("required"),
    managers: yup.array().required("required"),
    drivers: yup.array().required("required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationsSchema,
    onSubmit: handleFormAddSeries,
  });

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeChip = (event) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue("managers", value);
  };

  const handleChangeChip2 = (event) => {
    const {
      target: { value },
    } = event;
    formik.setFieldValue("drivers", value);
  };

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box height="100vh" display="flex" flexDirection="column">
          <Navbar />
          <Box
            display="flex"
            flexDirection="row"
            flex="flex-grow"
            height="100%"
          >
            <Sidenavbar />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="scroll"
            >
              {user.role.includes("guest") ? (
                <Typography>Intern Only!</Typography>
              ) : (
                <Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Start</TableCell>
                          <TableCell>End</TableCell>
                          <TableCell>Phase</TableCell>
                          <TableCell>Managers</TableCell>
                          <TableCell>Fahrer</TableCell>
                          <TableCell>Events</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {seriesList.map((serie) => (
                          <TableRow key={serie.name + "_SerieTableHead "}>
                            <TableCell>{serie.name}</TableCell>
                            <TableCell>
                              {new Date(serie.start).toLocaleDateString(
                                "de-De"
                              )}
                            </TableCell>
                            <TableCell>
                              {new Date(serie.end).toLocaleDateString("de-De")}
                            </TableCell>
                            <TableCell>Phase</TableCell>
                            <TableCell>
                              {serie.managers.map((user) => (
                                <Chip
                                  key={serie.name + "_Manager_" + user}
                                  label={user}
                                  size="small"
                                />
                              ))}
                            </TableCell>
                            <TableCell>
                              {serie.drivers.map((user) => (
                                <Chip
                                  key={serie.name + "_Drivers_" + user}
                                  label={user}
                                  size="small"
                                />
                              ))}
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => {
                                  handleEventOpen(serie);
                                }}
                              >
                                <Badge
                                  badgeContent={serie.events.length}
                                  color="primary"
                                >
                                  <ArrowCircleRightIcon />
                                </Badge>
                              </IconButton>
                            </TableCell>
                            <TableCell>blabla</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Box>
          </Box>
          {/** Admin Tools */}
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={eventOpen}
            onClick={handleEventClose}
          >
            <Box width="70%" height="70%" sx={{ overflow: "auto" }}>
              {seriesEvents.length
                ? seriesEvents.map((event) => (
                    <Box width="100%" key={event._id + "_box"} mt="0.2rem">
                      <EventSmallWidget key={event._id} event={event} />
                    </Box>
                  ))
                : null}
            </Box>
          </Backdrop>
          {/** Admin Tools */}
          {user.role.includes("admin") ? (
            <Fab
              color="primary"
              aria-label="add"
              display="block"
              sx={{ position: "absolute", right: "2%", bottom: "2%" }}
              onClick={setAddEventToggle}
            >
              <AddIcon />
            </Fab>
          ) : null}
          <Dialog open={isAddEvent} onClose={setAddEvent}>
            <form onSubmit={formik.handleSubmit}>
              <DialogContent>
                <Box display="flex" flexDirection="column" gap="0.5rem">
                  <DialogContentText>Füge ein Serie hinzu.</DialogContentText>
                  <TextField
                    label="Serie"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    name="name"
                    error={
                      Boolean(formik.touched.name) &&
                      Boolean(formik.errors.name)
                    }
                    helperText={formik.touched.name && formik.errors.name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <DateTimePicker
                    label="Start"
                    id="start"
                    value={dayjs(formik.values.start)}
                    onBlur={formik.handleBlur}
                    onChange={(value) => {
                      formik.setFieldValue("start", value);
                    }}
                    error={
                      Boolean(formik.touched.dob) && Boolean(formik.errors.dob)
                    }
                  />
                  <DateTimePicker
                    label="Ende"
                    id="end"
                    value={dayjs(formik.values.end)}
                    onBlur={formik.handleBlur}
                    onChange={(value) => {
                      formik.setFieldValue("end", value);
                    }}
                    error={
                      Boolean(formik.touched.dob) && Boolean(formik.errors.dob)
                    }
                  />
                  <TextField
                    label="Beschreibung"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    name="description"
                    error={
                      Boolean(formik.touched.description) &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    sx={{ gridColumn: "span 2" }}
                  />
                </Box>
                <InputLabel id="managers">Mangers</InputLabel>
                <Select
                  id="managers"
                  label="Managers"
                  labelId="managers"
                  multiple
                  value={formik.values.managers} // Update to use formik values
                  onChange={handleChangeChip}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(team).map((user) => (
                    <MenuItem
                      key={"selectedMangager " + team[user]._id}
                      value={team[user].lastName}
                      style={getStyles(user, user, theme)}
                    >
                      {team[user].lastName}
                    </MenuItem>
                  ))}
                </Select>
                <InputLabel id="drivers">Fahrer</InputLabel>
                <Select
                  id="drivers"
                  label="Fahrer"
                  labelId="drivers"
                  multiple
                  value={formik.values.drivers} // Update to use formik values
                  onChange={handleChangeChip2}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {Object.keys(team).map((user) => (
                    <MenuItem
                      key={"selectedDriver " + team[user]._id}
                      value={team[user].lastName}
                      style={getStyles(user, user, theme)}
                    >
                      {team[user].lastName}
                    </MenuItem>
                  ))}
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={setAddEventToggle}>Abbrechen</Button>
                <Button
                  type="submit"
                  sx={{
                    m: "2rem 0",
                    p: "1rem",
                    backgroundColor: palette.primary.main,
                    color: palette.background.alt,
                    "&:hover": { color: palette.primary.main },
                  }}
                >
                  hinzufügen
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default SerienPage;
