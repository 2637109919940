import {
  ManageAccountsOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Typography,
  Divider,
  useTheme,
  Button,
  Avatar,
} from "@mui/material";
import UserImage from "components/UserImage";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IrStatsChip from "components/IrStatsChip";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import CakeIcon from "@mui/icons-material/Cake";
import dayjs from "dayjs";

const UserWidget = ({ userId, picturePath }) => {
  const [user, setUser] = useState(null);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const acc = useSelector((state) => state.auth.user);
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;
  const isUser = user === null ? false : acc._id === userId ? true : false;
  const [irData, setIrData] = useState(null);

  const getUser = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/${userId}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    setUser(data);
    const main =
      "irmain" in data &&
      data.irmain !== null &&
      data.irmain !== undefined &&
      data.irsmurf !== ""
        ? data.irmain
        : "";
    const smurf =
      "irsmurf" in data &&
      data.irsmurf !== null &&
      data.irsmurf !== undefined &&
      data.irsmurf !== ""
        ? data.irsmurf
        : "";
    const ids =
      main.length > 0 && smurf.length > 0 ? main + "," + smurf : main + smurf;
    if (ids === "") {
    } else {
      const response2 = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/iracing/multiple`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ ids: ids }),
        }
      );
      var data2 = await response2.json();
      data2 = JSON.parse(data2);
      setIrData(data2);
    }
  };

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) {
    return null;
  }

  return (
    <WidgetWrapper>
      {/* FIRST ROW */}
      <FlexBetween gap="0.5rem" pb="1.1rem">
        <FlexBetween gap="1rem" onClick={() => navigate(`/profile/${userId}`)}>
          {picturePath === "" ? (
            <Avatar>{user.firstName[0] + user.lastName[0]}</Avatar>
          ) : (
            <UserImage image={picturePath} />
          )}
          <Box>
            <Typography
              variant="h4"
              color={dark}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.light,
                  cursor: "pointer",
                },
              }}
            >
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        </FlexBetween>
        {isUser && (
          <Button onClick={() => navigate(`/settings`)}>
            <ManageAccountsOutlined />
          </Button>
        )}
      </FlexBetween>

      <Divider />

      {/* SECOND ROW */}
      <Box p="1rem 0">
        <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
          <LocationOnOutlined fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>{user.location}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem">
          <CakeIcon fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>
            {dayjs(user.bday).format("DD.MM.YYYY")}
          </Typography>
        </Box>
      </Box>

      <Divider />

      {/* FOURTH ROW */}
      <Box p="1rem 0">
        <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
          Social Profiles
        </Typography>

        {user.twitch === "" || user.twitch === undefined ? null : (
          <FlexBetween gap="1rem" mb="0.5rem">
            <FlexBetween gap="1rem">
              <img src="../assets/twitch.png" alt="twitch" />
              <Box>
                <Typography color={main} fontWeight="500">
                  {user.twitch}
                </Typography>
                <Typography color={medium}>Twitch</Typography>
              </Box>
            </FlexBetween>
          </FlexBetween>
        )}
        {user.youtube === "" || user.youtube === undefined ? null : (
          <FlexBetween gap="1rem">
            <FlexBetween gap="1rem">
              <YouTubeIcon fontSize="large" sx={{ color: main }} />
              <Box>
                <Typography color={main} fontWeight="500">
                  {user.youtube}
                </Typography>
                <Typography color={medium}>YouTube</Typography>
              </Box>
            </FlexBetween>
          </FlexBetween>
        )}
        {user.facebook === "" || user.facebook === undefined ? null : (
          <FlexBetween gap="1rem">
            <FlexBetween gap="1rem">
              <FacebookIcon fontSize="large" sx={{ color: main }} />
              <Box>
                <Typography color={main} fontWeight="500">
                  {user.facebook}
                </Typography>
                <Typography color={medium}>Facebook</Typography>
              </Box>
            </FlexBetween>
          </FlexBetween>
        )}
        {user.instagram === "" || user.instagram === undefined ? null : (
          <FlexBetween gap="1rem">
            <FlexBetween gap="1rem">
              <InstagramIcon fontSize="large" sx={{ color: main }} />
              <Box>
                <Typography color={main} fontWeight="500">
                  {user.instagram}
                </Typography>
                <Typography color={medium}>Instagram</Typography>
              </Box>
            </FlexBetween>
          </FlexBetween>
        )}
        {user.twitter === "" || user.twitter === undefined ? null : (
          <FlexBetween gap="1rem">
            <FlexBetween gap="1rem">
              <TwitterIcon fontSize="large" sx={{ color: main }} />
              <Box>
                <Typography color={main} fontWeight="500">
                  {user.twitter}
                </Typography>
                <Typography color={medium}>Twitter</Typography>
              </Box>
            </FlexBetween>
          </FlexBetween>
        )}
      </Box>

      <Divider />
      {/* FOURTH ROW */}
      <Box p="1rem 0">
        <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
          Iracing Profiles
        </Typography>
        {irData === null ? (
          <Typography>No IR Acc connected</Typography>
        ) : "members" in irData ? (
          irData.members.map((acc) => (
            <Box mb="0.25rem" key={acc.cust_id + "_box"}>
              <Typography mb="0.5rem">
                {acc.display_name + " (#" + acc.cust_id + ")"}
              </Typography>
              <FlexBetween gap="1rem" mb="0.5rem">
                <IrStatsChip
                  id={acc.display_name + "_road"}
                  category="road"
                  group_name={acc.licenses[1].group_name}
                  safty={acc.licenses[1].safety_rating}
                  ir={
                    "irating" in acc.licenses[1]
                      ? acc.licenses[1].irating
                      : "----"
                  }
                />
                <IrStatsChip
                  id={acc.display_name + "_formula"}
                  category="formula"
                  group_name={acc.licenses[2].group_name}
                  safty={acc.licenses[2].safety_rating}
                  ir={
                    "irating" in acc.licenses[2]
                      ? acc.licenses[2].irating
                      : "----"
                  }
                />
                <IrStatsChip
                  id={acc.display_name + "_oval"}
                  category="oval"
                  group_name={acc.licenses[0].group_name}
                  safty={acc.licenses[0].safety_rating}
                  ir={
                    "irating" in acc.licenses[0]
                      ? acc.licenses[0].irating
                      : "----"
                  }
                />
                <IrStatsChip
                  id={acc.display_name + "_dirtroad"}
                  category="dirt_road"
                  group_name={acc.licenses[3].group_name}
                  safty={acc.licenses[3].safety_rating}
                  ir={
                    "irating" in acc.licenses[3]
                      ? acc.licenses[3].irating
                      : "----"
                  }
                />
                <IrStatsChip
                  id={acc.display_name + "_dirtoval"}
                  category="dirt_oval"
                  group_name={acc.licenses[2].group_name}
                  safty={acc.licenses[2].safety_rating}
                  ir={
                    "irating" in acc.licenses[2]
                      ? acc.licenses[2].irating
                      : "----"
                  }
                />
              </FlexBetween>
            </Box>
          ))
        ) : (
          <Typography>no IR Account hinterlegt</Typography>
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default UserWidget;
