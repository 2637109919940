import { Alert, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setErrorState } from "state/error";

const ErrorHandler = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.error.errorState);
  const errormsg = useSelector((state) => state.error.errorMessage);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setErrorState());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {errormsg}
      </Alert>
    </Snackbar>
  );
};

export default ErrorHandler;
