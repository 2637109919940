import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { DiagramColors } from "theme";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slider,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setChartState } from "state/charts";

function FuelArea({ Logdata }) {
  const [sessionState, setSessionState] = useState(
    Logdata.SessionState.replace(/ +/g, "")
  );
  const [chartData, setChartData] = useState(null);
  const [minVal, setMinVal] = useState(null);
  const [maxVal, setMaxVal] = useState(null);

  //Chart Settings
  const dispatch = useDispatch();
  const size = useSelector((state) => state.charts.FuelChart);
  const [isSetting, setIsSetting] = useState(false);

  const openDialog = () => {
    setIsSetting(true);
  };

  const closeDialog = () => {
    setIsSetting(false);
  };

  const data = () => {
    var dataarr = [];
    var min = 10000;
    var max = 0;
    for (let x = 0; x < Logdata[sessionState].length; x++) {
      let stintnr = "Stint" + Logdata[sessionState][x].Outing;
      for (let j = 0; j < Logdata[sessionState][x].Lapdata.length; j++) {
        let datapoint = {};
        datapoint.name = Logdata[sessionState][x].Lapdata[j].Lap;
        datapoint[stintnr] = Logdata[sessionState][x].Lapdata[j].FuelCons;
        dataarr.push(datapoint);
      }
      if (Logdata[sessionState][x].FuelValues.Min < min) {
        min = Logdata[sessionState][x].FuelValues.Min;
      }
      if (Logdata[sessionState][x].FuelValues.Max > max) {
        max = Logdata[sessionState][x].FuelValues.Max;
      }
    }
    setChartData(dataarr);
    setMinVal(min);
    setMaxVal(max);
  };

  useEffect(() => {
    data();
  }, [Logdata, size]);

  return (
    <Box order={size.position}>
      <Dialog open={isSetting} onClose={closeDialog}>
        <DialogTitle>ChartSettings</DialogTitle>
        <DialogContent>
          <DialogContentText>Breite</DialogContentText>
          <Slider
            onChange={(_, value) => {
              dispatch(
                setChartState({
                  subCat: "FuelChart",
                  param: "width",
                  value: value,
                })
              );
            }}
            aria-label="Chart-Size"
            value={size.width}
            valueLabelDisplay="auto"
            step={200}
            marks
            min={200}
            max={2000}
          />
          <DialogContentText>Höhe</DialogContentText>
          <Slider
            onChange={(_, value) => {
              dispatch(
                setChartState({
                  subCat: "FuelChart",
                  param: "height",
                  value: value,
                })
              );
            }}
            aria-label="Chart-Size"
            value={size.height}
            valueLabelDisplay="auto"
            step={50}
            marks
            min={50}
            max={500}
          />
          <DialogContentText>Reihenfolge</DialogContentText>
          <TextField
            variant="outlined"
            type="number"
            value={size.position}
            onChange={(event) => {
              dispatch(
                setChartState({
                  subCat: "FuelChart",
                  param: "position",
                  value: event.target.value,
                })
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader
          title="Verbrauch"
          action={
            <IconButton aria-label="settings" onClick={openDialog}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardContent>
          {chartData === null ? null : (
            <LineChart
              width={size.width}
              height={size.height}
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis
                domain={([dataMin, dataMax]) => {
                  return [minVal.toFixed(3), maxVal.toFixed(3)];
                }}
              />
              <Tooltip />
              {Logdata[sessionState].length
                ? Logdata[sessionState].map((stint) => (
                    <Line
                      key={"Stint" + stint.Outing}
                      type="monotone"
                      dot={true}
                      dataKey={"Stint" + stint.Outing}
                      stackId="a"
                      stroke={DiagramColors[stint.Outing - 1]}
                      fill={DiagramColors[stint.Outing - 1]}
                    />
                  ))
                : null}
            </LineChart>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}

export default FuelArea;
