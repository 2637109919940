import {
  Box,
  Card,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  Paper,
  Backdrop,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CardHeader,
  CardContent,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Navbar from "scenes/navbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";

import { DeleteForeverOutlined } from "@mui/icons-material";

const SessionsPage = () => {
  const [sessions, setSessions] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const navigate = useNavigate();
  const [isHelp, setIsHelp] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const getSessions = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/telemetrie/sessions`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    setSessions(data);
  };

  const delSes = async (sessionid, teamname) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/telemetrie/sessions/${sessionid}/${teamname}`,
      {
        method: "DELETE",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  useEffect(() => {
    getSessions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function splitInfos(infos) {
    var strsplit = infos.split(" - ");
    let Track = strsplit[1].split(" @ ");
    Track = Track[1];
    return Track;
  }

  const handleOpenHelp = () => {
    setIsHelp(true);
  };

  const handleCloseHelp = () => {
    setIsHelp(false);
  };

  if (!sessions) return null;

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box height="100vh" display="flex" flexDirection="column">
          <Navbar />
          <Box
            display="flex"
            flexDirection="row"
            flex="flex-grow"
            height="100%"
          >
            <Sidenavbar />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="scroll"
            >
              <Box m="1rem">
                <Button variant="outlined" onClick={handleOpenHelp}>
                  Hilfe für JRT-Setup
                </Button>
                <Backdrop
                  open={isHelp}
                  onClick={handleCloseHelp}
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                >
                  <Card>
                    <CardHeader title="JRT Setup für Telemetrie" />
                    <CardContent>
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>1</Avatar>
                          </ListItemAvatar>
                          <ListItemText>Starte JRT</ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>2</Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            In jrtconfig.html wähle den Punkt "Broadcast".
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>3</Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            Aktiviere Broadcast logging.
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>4</Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            Trage "https://voltasimracing.de/api/telemetrie/jrt"
                            bei log_post.php address ein.
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>5</Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            Setze den Broadcast Mode auf Mode 0 oder Mode 1.
                          </ListItemText>
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>6</Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            Speichere mit Save und starte JRT neu.
                          </ListItemText>
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Backdrop>
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow key="tablehead">
                          <TableCell></TableCell>
                          <TableCell>Zeitpunkt</TableCell>
                          <TableCell>Fahrer</TableCell>
                          <TableCell>SessionId</TableCell>
                          <TableCell>SessionType</TableCell>
                          <TableCell>Auto</TableCell>
                          <TableCell>Strecke</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sessions.length
                          ? sessions.map((session) => (
                              <TableRow
                                key={session.sessionid + session.teamname}
                              >
                                <TableCell>
                                  <IconButton
                                    onClick={() =>
                                      delSes(
                                        session.sessionid,
                                        session.teamname
                                      )
                                    }
                                  >
                                    <DeleteForeverOutlined />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  {new Date(session.date).toLocaleString(
                                    "de-DE",
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                      hour: "numeric",
                                      minute: "numeric",
                                    }
                                  )}
                                </TableCell>
                                <TableCell>{session.teamname}</TableCell>
                                <TableCell>{session.sessionid}</TableCell>
                                <TableCell>{session.sessiontype}</TableCell>
                                <TableCell>{session.carname}</TableCell>
                                <TableCell>
                                  {splitInfos(session.info)}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() => {
                                      navigate(
                                        `/sessions/${session.sessionid}/${session.teamname}`
                                      );
                                    }}
                                  >
                                    <InfoOutlinedIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SessionsPage;
