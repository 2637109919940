import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Help, CalendarMonthOutlined } from "@mui/icons-material";
import CastIcon from "@mui/icons-material/Cast";
import GroupsIcon from "@mui/icons-material/Groups";

const Sidenavbar = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      sx={{
        width: "200px",
        bgcolor: palette.neutral.light,
      }}
    >
      <MenuList>
        <MenuItem onClick={() => navigate("/event")}>
          <ListItemIcon>
            <CalendarMonthOutlined />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: "1rem" }}
            primary="Termine & Events"
          />
        </MenuItem>
        <MenuItem onClick={() => navigate("/calender")}>
          <ListItemText inset>Kalender</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate("/series")}>
          <ListItemText inset>Serien</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate("/event")}>
          <ListItemText inset>Events</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate("/results")}>
          <ListItemText inset>Results</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/sessions")}>
          <ListItemIcon>
            <CastIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: "1rem" }}
            primary="Sessions"
          />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => navigate("/team")}>
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: "1rem" }}
            primary="Team"
          />
        </MenuItem>
        <MenuItem onClick={() => navigate("/settings")}>
          <ListItemText inset>Profil</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ fontSize: "1rem" }}
            primary="Help"
          />
        </MenuItem>
      </MenuList>
    </Box>
  );
};

export default Sidenavbar;
