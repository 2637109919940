import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LapChart: {
    height: 300,
    width: 800,
    position: 1,
    show: true,
  },
  FuelChart: {
    height: 300,
    width: 800,
    position: 6,
    show: true,
  },
  FuelHisto: {
    height: 300,
    width: 500,
    position: 4,
    show: true,
  },
  LapDelta: {
    height: 250,
    width: 500,
    position: 2,
    show: true,
  },
  FuelScatter: {
    height: 400,
    width: 600,
    position: 8,
    show: true,
  },
  FuelDelta: {
    height: 250,
    width: 500,
    position: 5,
    show: true,
  },
  FuelUse: {
    height: 400,
    width: 600,
    position: 7,
    show: true,
  },
  LapHisto: {
    height: 300,
    width: 500,
    position: 3,
    show: true,
  },
};

export const chartSlice = createSlice({
  name: "charts",
  initialState,
  reducers: {
    setChartState: (state, action) => {
      state[action.payload.subCat][action.payload.param] = action.payload.value;
    },
  },
});

export const { setChartState } = chartSlice.actions;
export default chartSlice.reducer;
