import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";

function StintTable({ Stint }) {
  const [sessionState, setSessionState] = useState(
    Stint.Type.replace(/ +/g, "")
  );

  function prettyOutputLap(input) {
    let minutes = Math.floor(input / 60);
    let secounds = (Math.floor((input - minutes * 60) * 1000) / 1000).toFixed(
      3
    );
    var string = minutes + ":" + ("0" + secounds).slice(-6);
    return string;
  }

  function prettyOutputTime(input) {
    if (input === "--") {
      return "--";
    } else {
      let hours = Math.floor(input / 3600);
      let minutes = Math.floor((input - hours * 3600) / 60);
      let secounds = Math.floor(input - hours * 3600 - minutes * 60);
      var string =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (secounds < 10 ? "0" + secounds : secounds);
      return string;
    }
  }

  function calcOutLap() {
    if (
      Stint.Lapdata[0].StintLap === "PIT" &&
      Stint.Lapdata[0].Laptime === "--"
    ) {
      if (Stint.Lapdata.length > 1 && Stint.Lapdata[1].StintLap !== 1) {
        return Stint.Lapdata[1].Laptime;
      } else {
        return 0;
      }
    } else {
      return Stint.Lapdata[0].Laptime;
    }
  }

  const OutLap = calcOutLap();

  return (
    <Box>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <FlexBetween
            width="75%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <Box>
              <Typography>{Stint.Type + " Stint " + Stint.Outing}</Typography>
            </Box>
            <Box>
              <Typography>{Stint.Name}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Avg. Laptime</Typography>
              <Typography>
                {prettyOutputLap(Stint.lastvalues.AvgLapTime)}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Avg. Fuel Cons</Typography>
              <Typography>{Stint.lastvalues.AvgFuelCons}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>OutLap</Typography>
              <Typography>{prettyOutputTime(OutLap)}</Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Stinttime</Typography>
              <Typography>
                {prettyOutputTime(Stint.lastvalues.StintTime)}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>InLap</Typography>
              <Typography>
                {Stint.Lapdata.slice(-1)[0].StintLap === "PIT"
                  ? prettyOutputTime(Stint.Lapdata.slice(-1)[0].Laptime)
                  : "--"}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>TotalTime</Typography>
              <Typography>
                {Stint.Lapdata.slice(-1)[0].StintLap === "PIT"
                  ? prettyOutputTime(
                      Stint.Lapdata.slice(-1)[0].Laptime +
                        Stint.lastvalues.StintTime +
                        OutLap
                    )
                  : prettyOutputTime(Stint.lastvalues.StintTime + OutLap)}
              </Typography>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography>Runden</Typography>
              <Typography>{Stint.Lapdata.length}</Typography>
            </Box>
          </FlexBetween>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Runde</TableCell>
                  <TableCell>Stint</TableCell>
                  <TableCell>Laptime</TableCell>
                  <TableCell>Stinttime</TableCell>
                  <TableCell>Time Remaining</TableCell>
                  <TableCell>Laps Remaining</TableCell>
                  <TableCell>Fuel</TableCell>
                  <TableCell>Verbrauch</TableCell>
                  <TableCell>Inc</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Stint.Lapdata.map((Lap, index) => (
                  <TableRow
                    key={Stint.Type + " Stint " + Stint.Outing + Lap.Lap}
                  >
                    <TableCell>{Lap.Lap}</TableCell>
                    <TableCell>{Lap.StintLap}</TableCell>
                    <TableCell>{prettyOutputLap(Lap.Laptime)}</TableCell>
                    <TableCell>{prettyOutputTime(Lap.StintTime)}</TableCell>
                    <TableCell>{prettyOutputTime(Lap.TimeRemain)}</TableCell>
                    <TableCell>{Lap.LapsRemain}</TableCell>
                    <TableCell>{Lap.FuelLevel}</TableCell>
                    <TableCell>{Lap.FuelCons}</TableCell>
                    <TableCell>{Lap.Inc}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default StintTable;
