const iracingSelects = {
  none: {
    id: "none",
    name: "Noch nicht fix",
    class: "",
    img: "/none.jpg",
  },
  GT3_Audi: {
    id: "GT3_Audi",
    name: "Audi R8 LMS EVO II GT3",
    class: "GT3",
    img: "/GT3_Audi.png",
  },
  GT3_Bmw: {
    id: "GT3_Bmw",
    name: "BMW M4 GT3",
    class: "GT3",
    img: "/GT3_Bmw.png",
  },
  GT3_Porsche: {
    id: "GT3_Porsche",
    name: "Porsche 911 GT3 R",
    class: "GT3",
    img: "/GT3_Porsche.png",
  },
  GT3_Merc: {
    id: "GT3_Merc",
    name: "Mercedes-AMG GT3",
    class: "GT3",
    img: "/GT3_Merc.png",
  },
  GT3_Lambo: {
    id: "GT3_Lambo",
    name: "Lamborghini Huracán GT3 EVO",
    class: "GT3",
    img: "/GT3_Lambo.png",
  },
  GT3_Fefe: {
    id: "GT3_Fefe",
    name: "Ferrari 296 GT3",
    class: "GT3",
    img: "/GT3_Fefe.png",
  },
  LMDH_Bmw: {
    id: "LMDH_Bmw",
    name: "BMW M Hybrid V8",
    class: "LMDH",
    img: "/LMDH_Bmw.png",
  },
};

export default iracingSelects;
