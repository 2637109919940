import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
} from "@mui/material";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { DateTimePicker } from "@mui/x-date-pickers";
import { setEvent } from "state";
import iracingSelects from "components/iracingSelects";
import OutlinedInput from "@mui/material/OutlinedInput";

const EditEvent = ({ event }) => {
  const isNonMobileScreens = useMediaQuery("(min-width:1250px)");
  const { palette } = useTheme();
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const [editValues, setEditValues] = useState([]);
  const [seriesList, setSeriesList] = useState([]);
  const [editSeries, setEditSeries] = useState("keine");

  const handleFormAddEvent = async (values, onSubmitProps) => {
    const addEventResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/update/basic`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    );
    const addEventLog = await addEventResponse.json();
    dispatch(setEvent(addEventLog));
    onSubmitProps.resetForm();
  };

  async function patchEventCars() {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/cars`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cars: editValues }),
      }
    );
    const updatedPost = await response.json();
    setEvent(updatedPost);
  }

  async function patchEventSerie() {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/series`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ series: editSeries }),
      }
    );
    const updatedPost = await response.json();
    setEvent(updatedPost);
  }

  const initialValues = {
    eventTitle: event.eventTitle,
    eventType: event.eventType,
    eventStart: new Date(event.eventStart),
    eventEnd: new Date(event.eventEnd),
    eventDescription: event.eventDescription,
  };

  const validationsSchema = yup.object().shape({
    eventTitle: yup.string().required("required"),
    eventType: yup.string().required("required"),
    eventStart: yup.date().required("required"),
    eventEnd: yup.date().required("required"),
    eventDescription: yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationsSchema,
    onSubmit: handleFormAddEvent,
  });

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const theme = useTheme();

  const handleChangeChip = (event) => {
    const {
      target: { value },
    } = event;
    setEditValues(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    setEditValues(event.Cars === undefined ? [] : event.Cars);
  }, [event]);

  const getSeriesList = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/series`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setSeriesList(data);
  };

  useEffect(() => {
    getSeriesList();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap="0.5rem">
      <Card>
        <CardHeader title="Event bearbeiten" />
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            <Box display="flex" flexDirection="column" gap="0.5rem">
              <TextField
                label="Event"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.eventTitle}
                name="eventTitle"
                error={
                  Boolean(formik.touched.eventTitle) &&
                  Boolean(formik.errors.eventTitle)
                }
                helperText={
                  formik.touched.eventTitle && formik.errors.eventTitle
                }
                sx={{ gridColumn: "span 2" }}
              />
              <Select
                labelId="Eventtyp"
                name="eventType"
                value={formik.values.eventType}
                label="eventType"
                onChange={formik.handleChange}
              >
                <MenuItem key="1" value={"Iracing"}>
                  Iracing
                </MenuItem>
                <MenuItem key="2" value={"Bday"}>
                  Geburtstag
                </MenuItem>
                <MenuItem key="3" value={"Other"}>
                  Anderes
                </MenuItem>
              </Select>
              <DateTimePicker
                label="Start"
                id="eventStart"
                value={dayjs(formik.values.eventStart)}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  formik.setFieldValue("eventStart", value);
                }}
                error={
                  Boolean(formik.touched.dob) && Boolean(formik.errors.dob)
                }
              />
              <DateTimePicker
                label="Ende"
                id="eventEnd"
                value={dayjs(formik.values.eventEnd)}
                onBlur={formik.handleBlur}
                onChange={(value) => {
                  formik.setFieldValue("eventEnd", value);
                }}
                error={
                  Boolean(formik.touched.dob) && Boolean(formik.errors.dob)
                }
              />
              <TextField
                label="Beschreibung"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.eventDescription}
                name="eventDescription"
                error={
                  Boolean(formik.touched.eventDescription) &&
                  Boolean(formik.errors.eventDescription)
                }
                helperText={
                  formik.touched.eventDescription &&
                  formik.errors.eventDescription
                }
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
          </CardContent>
          <CardActions>
            <Button
              type="submit"
              sx={{
                m: "2rem 0",
                p: "1rem",
                backgroundColor: palette.primary.main,
                color: palette.background.alt,
                "&:hover": { color: palette.primary.main },
              }}
            >
              Ändern
            </Button>
          </CardActions>
        </form>
      </Card>

      <Card>
        <CardHeader title="Iracing Setup" />
        <CardContent>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="selectedCarLabel">Autos</InputLabel>
            <Select
              id="editTeamInput_car"
              label="Auto"
              labelId="selectedCarLabel"
              multiple
              value={editValues}
              onChange={handleChangeChip}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {Object.keys(iracingSelects).map((car) => (
                <MenuItem
                  key={"selectedCar " + iracingSelects[car].id}
                  value={iracingSelects[car].name}
                  style={getStyles(car, car, theme)}
                >
                  {iracingSelects[car].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
        <CardActions>
          <Button onClick={patchEventCars}>Autos hinzufügen</Button>
        </CardActions>
      </Card>

      <Card>
        <CardHeader title="Link Series" />
        <CardContent>
          {console.log(event)}
          <Typography>Current Series</Typography>
          {"Series" in event ? (
            <Typography>{event.Series}</Typography>
          ) : (
            <Typography>Keine Zugewissen</Typography>
          )}
          <Divider />

          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="selectedCarLabel">Serie</InputLabel>
            <Select
              id="add_series"
              label="Serie"
              labelId="selectedSeriesLabel"
              value={editSeries}
              onChange={(event) => setEditSeries(event.target.value)}
            >
              <MenuItem
                key={"selectedSeries " + "none"}
                value={"keine"}
              ></MenuItem>
              {Object.keys(seriesList).map((series) => (
                <MenuItem
                  key={"selectedSeries " + seriesList[series]._id}
                  value={seriesList[series].name}
                >
                  {seriesList[series].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
        <CardActions>
          <Button onClick={patchEventSerie}>Serie hinzufügen</Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default EditEvent;
