import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  useTheme,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Navbar from "scenes/navbar";
import ProfilForm from "./ProfilForm";
import Dropzone from "react-dropzone";
import FlexBetween from "components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ErrorHandler from "components/ErrorHandler";
import { setErrorMessage, setErrorState } from "state/error";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "state";
import UserWidget from "scenes/widgets/UserWidget";
import FlexBetweenTop from "components/FlexBetweenTop";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";
import PasswordChangeForm from "./ChangePassword";

const ProfilSettingsPage = () => {
  //const { userId } = useParams();
  const { _id } = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const { palette } = useTheme();
  const [file, setFile] = useState("");
  const dispatch = useDispatch();

  const handleUpload = async (e) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const updatedProfil = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/${user._id}/image`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    if ("error" in updatedProfil) {
      dispatch(setErrorState());
      dispatch(setErrorMessage({ error: updatedProfil.error }));
    } else {
      setLogin({
        user: updatedProfil,
        token: token,
      });
    }
  };

  if (!user) return null;

  return (
    <Box height="100vh" display="flex" flexDirection="column" overflow="hidden">
      <Navbar />
      <Box display="flex" flexDirection="row" flex="flex-grow" height="100%">
        <Sidenavbar />
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          overflow="scroll"
        >
          <ErrorHandler />
          <FlexBetween m="1rem">
            <UserWidget userId={user._id} picturePath={user.picturePath} />
            <ProfilForm />
            <Card>
              <CardHeader title="Passwort ändern" />
              <CardContent>
                <PasswordChangeForm />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Profilbild" />
              <CardContent>
                <Dropzone
                  acceptedFiles=".jpg,.jpeg,.png"
                  multiple={false}
                  onDrop={(acceptedFiles) => {
                    setFile(acceptedFiles[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      border={`2px dashed ${palette.primary.main}`}
                      p="1rem"
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      <input {...getInputProps()} />
                      {!file ? (
                        <p>Add Picture Here</p>
                      ) : (
                        <FlexBetween>
                          <Typography>{"Bild im Zwischenspeicher"}</Typography>
                          <EditOutlinedIcon />
                        </FlexBetween>
                      )}
                    </Box>
                  )}
                </Dropzone>
              </CardContent>
              <CardActions>
                <Button onClick={handleUpload}>Bild ändern</Button>
              </CardActions>
            </Card>
          </FlexBetween>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfilSettingsPage;
