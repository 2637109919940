import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorState: false,
  errorMessage: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setErrorState: (state) => {
      state.errorState = !state.errorState;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload.error;
    },
  },
});

export const { setErrorState, setErrorMessage } = errorSlice.actions;
export default errorSlice.reducer;
