import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Rating,
  Slider,
  Divider,
  CardActions,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setEvent } from "state";

const Wish = ({ event }) => {
  const [userList, setUserList] = useState({});
  const [myWish, setMyWish] = useState([]);
  const loggedInUserId = useSelector((state) => state.auth.user._id);
  const [value1, setValue1] = useState([0, 5]);
  const token = useSelector((state) => state.auth.token);
  const [isMeEvent, setIsMeEvent] = useState(false);

  const minDistance = 0;

  async function patchPref() {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/pref`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cars: myWish,
          size: value1,
          userId: loggedInUserId,
        }),
      }
    );
    const updatedPost = await response.json();
    setEvent(updatedPost);
  }

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };

  function mergeUserList() {
    var obj = {
      ...event.eventYes,
      ...event.eventMaybe,
    };
    setUserList(obj);
  }

  function valuetext(value) {
    return `${value}`;
  }

  function checkWishes() {
    let content = [];
    if (Object.keys(userList).length === 0) {
    } else {
      if (loggedInUserId in userList) {
        setIsMeEvent(true);
        if ("Cars" in userList[loggedInUserId]) {
          for (var car in event.Cars) {
            let index = userList[loggedInUserId].Cars.findIndex(
              (x) => x.car === event.Cars[car]
            );
            if (index === -1) {
              content.push({ car: event.Cars[car], rating: null });
            } else {
              content.push({
                car: userList[loggedInUserId].Cars[car].car,
                rating: userList[loggedInUserId].Cars[car].rating,
              });
            }
          }
        } else {
          for (var car in event.Cars) {
            content.push({ car: event.Cars[car], rating: null });
          }
        }
        setMyWish(content);
      }
    }
  }

  function checkTeamSize() {
    if (Object.keys(userList).length === 0) {
    } else {
      if (loggedInUserId in userList) {
        if ("Size" in userList[loggedInUserId]) {
          setValue1(userList[loggedInUserId].Size);
        }
      }
    }
  }

  function changeCarRating(car, newValue) {
    let changeRating = myWish.findIndex((x) => x.car === car);
    const newWish = [...myWish]; // Create a new array to ensure immutability
    newWish[changeRating] = { ...newWish[changeRating], rating: newValue };
    setMyWish(newWish);
  }

  const marks = [
    { value: 0, label: 0 },
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ];

  useEffect(() => {
    mergeUserList();
  }, [event]);

  useEffect(() => {
    checkWishes();
  }, [userList]);

  useEffect(() => {
    checkWishes();
    checkTeamSize();
  }, [userList]);

  return (
    <Box display="flex" flexDirection="column" gap="2rem">
      {isMeEvent ? (
        <Box>
          {myWish.length ? (
            <Card>
              <CardHeader title="Deine Vorlieben für das Event" />
              <CardContent>
                <Box mb="1rem">
                  {myWish.map((car, index) => (
                    <Box key={"box_car_rating_me" + index}>
                      <Typography key={"text_car_rating_me_" + index}>
                        {car.car}
                      </Typography>
                      <Rating
                        key={"rating_car_rating_me_" + index}
                        name={car.car}
                        value={myWish[index].rating}
                        onChange={(event, newValue) =>
                          changeCarRating(car.car, newValue)
                        }
                      ></Rating>
                    </Box>
                  ))}
                </Box>
                <Divider />
                <Box mt="1rem">
                  <Typography>Teamgröße</Typography>
                  <Slider
                    getAriaLabel={() => "Minimum distance"}
                    value={value1}
                    onChange={handleChange1}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                    max={10}
                    steps={1}
                    marks={marks}
                  />
                </Box>
              </CardContent>
              <CardActions>
                <Button onClick={patchPref}>Send</Button>
              </CardActions>
            </Card>
          ) : (
            <Typography>Setup Event first</Typography>
          )}
        </Box>
      ) : null}
      <Card>
        <CardHeader title="Teamvorlieben" />
        <CardContent>
          {Object.keys(userList).length
            ? Object.keys(userList).map((user) => (
                <Box key={"box_" + user} mt="0.5rem" mb="0.5rem">
                  <Typography
                    key={"user_key_" + user}
                    component={"span"}
                    variant={"h5"}
                    mb="0.5rem"
                  >
                    {userList[user].firstName + " " + userList[user].lastName}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    gap="2rem"
                  >
                    {userList[user].Cars ? (
                      <Box key={"box_2_" + user} mt="0.25rem" mb="0.5rem">
                        {userList[user].Cars.map((ti) => (
                          <Box key={"box_car_rating_" + ti.car + user}>
                            <Typography
                              key={"text_car_rating_" + ti.car + user}
                            >
                              {ti.car}
                            </Typography>
                            <Rating
                              key={"rating_carrating_" + ti.car + user}
                              name={ti.car}
                              value={ti.rating}
                            />
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      <Typography mb="0.5rem" variant="subtitle2">
                        noch nichts festgelegt
                      </Typography>
                    )}
                    {userList[user].Size ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        m="2rem"
                      >
                        <Typography>Teamgröße</Typography>
                        <FlexBetween>
                          <Typography>{userList[user].Size[0]}</Typography>
                          <Typography>bis</Typography>
                          <Typography>{userList[user].Size[1]}</Typography>
                        </FlexBetween>
                      </Box>
                    ) : (
                      <Typography mb="0.5rem" variant="subtitle2">
                        noch nichts festgelegt
                      </Typography>
                    )}
                  </Box>
                  <Divider />
                </Box>
              ))
            : null}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Wish;
