import { Avatar, Chip, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FlexBetween from "./FlexBetween";

const IrStatsChip = ({ category, group_name, safty, ir }) => {
  const [color, setColor] = useState(null);
  const [type, setType] = useState(null);

  const chipcolor = () => {
    if (group_name == "Class A") {
      var c = "primary";
    } else if (group_name == "Class B") {
      var c = "success";
    } else if (group_name == "Class C") {
      var c = "secondary";
    } else if (group_name == "Class D") {
      var c = "warning";
    } else {
      var c = "error";
    }
    setColor(c);
  };

  const chiptype = () => {
    if (category == "road") {
      var t = "S";
    } else if (category == "formula") {
      var t = "F";
    } else if (category == "oval") {
      var t = "O";
    } else if (category == "dirt_road") {
      var t = "DR";
    } else if (category == "dirt_oval") {
      var t = "DO";
    }
    setType(t);
  };

  useEffect(() => {
    chipcolor();
    chiptype();
  }, []);

  return (
    <Box>
      {color === null ? null : (
        <Chip
          variant="outlined"
          avatar={<Avatar sx={{ bgcolor: { color } }}>{type}</Avatar>}
          label={
            <FlexBetween gap=".5rem">
              <Typography variant="caption">{safty}</Typography>
              <Typography variant="subtitle1">{ir}</Typography>
            </FlexBetween>
          }
          color={color}
        />
      )}
    </Box>
  );
};

export default IrStatsChip;
