import {
  Box,
  Button,
  Dialog,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  CardHeader,
  Slider,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CardContent,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setChartState } from "state/charts";
import { useEffect, useState } from "react";

function FuelTable({ Stint }) {
  const FuelLevel =
    Stint === null ? null : Stint.Lapdata.slice(-1)[0].FuelLevel;
  const LapsRemain = Stint.Lapdata.slice(-1)[0].LapsRemain;

  //Chart Settings
  const dispatch = useDispatch();
  const size = useSelector((state) => state.charts.FuelUse);
  const [isSetting, setIsSetting] = useState(false);

  const openDialog = () => {
    setIsSetting(true);
  };

  const closeDialog = () => {
    setIsSetting(false);
  };

  return (
    <Box order={size.position}>
      {Stint === null ? null : (
        <>
          <Dialog open={isSetting} onClose={closeDialog}>
            <DialogTitle>ChartSettings</DialogTitle>
            <DialogContent>
              <DialogContentText>Breite</DialogContentText>
              <Slider
                onChange={(_, value) => {
                  dispatch(
                    setChartState({
                      subCat: "FuelUse",
                      param: "width",
                      value: value,
                    })
                  );
                }}
                aria-label="Chart-Size"
                value={size.width}
                valueLabelDisplay="auto"
                step={200}
                marks
                min={200}
                max={2000}
              />
              <DialogContentText>Höhe</DialogContentText>
              <Slider
                onChange={(_, value) => {
                  dispatch(
                    setChartState({
                      subCat: "FuelUse",
                      param: "height",
                      value: value,
                    })
                  );
                }}
                aria-label="Chart-Size"
                value={size.height}
                valueLabelDisplay="auto"
                step={50}
                marks
                min={50}
                max={500}
              />
              <DialogContentText>Reihenfolge</DialogContentText>
              <TextField
                variant="outlined"
                type="number"
                value={size.position}
                onChange={(event) => {
                  dispatch(
                    setChartState({
                      subCat: "FuelUse",
                      param: "position",
                      value: event.target.value,
                    })
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          <Card>
            <CardHeader
              title="Stint Verbrauch"
              action={
                <IconButton aria-label="settings" onClick={openDialog}>
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <CardContent>
              <TableContainer>
                <Table
                  sx={{ width: size.width, height: size.height }}
                  size="small"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Stint Basis</TableCell>
                      <TableCell align="right">Verbrauch</TableCell>
                      <TableCell align="right">Runden</TableCell>
                      <TableCell align="right">Tanken</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key="fuelTable_1L"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Letzte Runde
                      </TableCell>
                      <TableCell align="right">
                        {Stint.Lapdata.slice(-1)[0].FuelCons === null
                          ? "---"
                          : Stint.Lapdata.slice(-1)[0].FuelCons.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {Stint.Lapdata.slice(-1)[0].FuelCons === null
                          ? "---"
                          : (
                              FuelLevel / Stint.Lapdata.slice(-1)[0].FuelCons
                            ).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {(
                          LapsRemain * Stint.Lapdata.slice(-1)[0].FuelCons -
                          FuelLevel
                        ).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key="fuelTable_5L"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Letzten 5 Runde
                      </TableCell>
                      <TableCell align="right">
                        {Stint.FuelValues.Last5 === null
                          ? "---"
                          : Stint.FuelValues.Last5.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {Stint.FuelValues.Last5 === null
                          ? "---"
                          : (FuelLevel / Stint.FuelValues.Last5).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {(
                          LapsRemain * Stint.FuelValues.Last5 -
                          FuelLevel
                        ).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key="fuelTable_stint"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Stint
                      </TableCell>
                      <TableCell align="right">
                        {Stint.FuelValues.Average.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {(FuelLevel / Stint.FuelValues.Average).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {(
                          LapsRemain * Stint.FuelValues.Average -
                          FuelLevel
                        ).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key="fuelTable_min"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Minimum
                      </TableCell>
                      <TableCell align="right">
                        {Math.min(Stint.FuelValues.Min).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {(FuelLevel / Math.min(Stint.FuelValues.Min)).toFixed(
                          2
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {(
                          LapsRemain * Math.min(Stint.FuelValues.Min) -
                          FuelLevel
                        ).toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow
                      key="fuelTable_max"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Maximum
                      </TableCell>
                      <TableCell align="right">
                        {Math.max(Stint.FuelValues.Max).toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {(FuelLevel / Math.max(Stint.FuelValues.Max)).toFixed(
                          2
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {(
                          LapsRemain * Math.max(Stint.FuelValues.Max) -
                          FuelLevel
                        ).toFixed(1)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
}

export default FuelTable;
