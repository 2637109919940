import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import EventSmallWidget from "scenes/widgets/EventSmallWidget";
import FlexBetween from "components/FlexBetween";
import { useEffect, useState } from "react";
import { setEvents } from "state";
import CalenderWidget from "scenes/widgets/CalenderWidget";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";

const CalenderPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1250px)");
  const { palette } = useTheme();
  const token = useSelector((state) => state.auth.token);
  const events = useSelector((state) => state.auth.events);
  const dispatch = useDispatch();
  const timeNow = new Date();
  const [pastEvents, setPastEvents] = useState([]);
  const [ongoingEvents, setOngoingEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const user = useSelector((state) => state.auth.user);

  function splitEventsTime() {
    const timeNow1 = new Date();
    const pastEvents1 = [];
    const ongoingEvents1 = [];
    const futureEvents1 = [];

    events.forEach((event) => {
      const eventStart = new Date(event.eventStart);
      const eventEnd = new Date(event.eventEnd);

      if (eventEnd < timeNow1) {
        // Event has already ended
        pastEvents1.push(event);
      } else if (eventStart <= timeNow1 && eventEnd >= timeNow1) {
        // Event is currently ongoing
        ongoingEvents1.push(event);
      } else {
        // Event is in the future
        futureEvents1.push(event);
      }
    });

    setPastEvents(pastEvents1);
    setOngoingEvents(ongoingEvents1);
    setFutureEvents(futureEvents1);
  }

  const getEvents = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/event`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setEvents({ events: data }));
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    splitEventsTime();
  }, [events]);

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box height="100vh" display="flex" flexDirection="column">
          <Navbar />
          <Box
            display="flex"
            flexDirection="row"
            flex="flex-grow"
            height="100%"
          >
            <Sidenavbar />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="scroll"
            >
              <Box
                width="100%"
                padding="2rem 6%"
                display={isNonMobileScreens ? "flex" : "block"}
                flexDirection="column"
                gap="0.5rem"
                justifyContent="space-between"
              >
                <Box
                  display={isNonMobileScreens ? "flex" : "block"}
                  justifyContent="space-between"
                  alignItems="top"
                  gap="1rem"
                  width="100%"
                >
                  <Box flexDirection="column" flexGrow="4" flexBasis="75%">
                    <CalenderWidget events={events} />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="1rem"
                    flexGrow="2"
                    flexBasis="25%"
                  >
                    <Box>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Ongoing
                        </AccordionSummary>
                        <AccordionDetails>
                          <FlexBetween flexDirection="column" gap="0.5rem">
                            {ongoingEvents.length
                              ? ongoingEvents.map((event) => (
                                  <>
                                    <Box width="100%" key={event._id + "_box"}>
                                      <EventSmallWidget
                                        key={event._id}
                                        event={event}
                                      />
                                    </Box>
                                  </>
                                ))
                              : null}
                          </FlexBetween>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Box>
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Future
                        </AccordionSummary>
                        <AccordionDetails>
                          <FlexBetween flexDirection="column" gap="0.5rem">
                            {futureEvents.length
                              ? futureEvents.map((event) => (
                                  <>
                                    <Box width="100%" key={event._id + "_box"}>
                                      <EventSmallWidget
                                        key={event._id}
                                        event={event}
                                      />
                                    </Box>
                                  </>
                                ))
                              : null}
                          </FlexBetween>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Box>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {" "}
                          Past
                        </AccordionSummary>
                        <AccordionDetails>
                          <FlexBetween flexDirection="column" gap="0.5rem">
                            {pastEvents.length
                              ? pastEvents.map((event) => (
                                  <>
                                    <Box width="100%" key={event._id + "_box"}>
                                      <EventSmallWidget
                                        key={event._id}
                                        event={event}
                                      />
                                    </Box>
                                  </>
                                ))
                              : null}
                          </FlexBetween>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CalenderPage;
