import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
  useTheme,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableBody,
  Badge,
  IconButton,
  Chip,
  Fab,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { DateTimePicker } from "@mui/x-date-pickers";
import { setEvents } from "state";
import { useFormik } from "formik";
import dayjs from "dayjs";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CalendarDownloadButton from "components/CalendarDownloadButton";
import FlexBetween from "components/FlexBetween";

const EventListPage = () => {
  const [isAddEvent, setAddEvent] = useState(false);
  const { palette } = useTheme();
  const token = useSelector((state) => state.auth.token);
  const events = useSelector((state) => state.auth.events);
  const dispatch = useDispatch();
  const timeNow = new Date();
  const user = useSelector((state) => state.auth.user);
  const [eventList, setEventList] = useState([]);
  const loggedInUserId = useSelector((state) => state.auth.user._id);
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const initialValues = {
    eventTitle: "",
    eventType: "Other",
    eventStart: timeNow,
    eventEnd: timeNow,
    eventDescription: "",
  };

  const validationsSchema = yup.object().shape({
    eventTitle: yup.string().required("required"),
    eventType: yup.string().required("required"),
    eventStart: yup.date().required("required"),
    eventEnd: yup.date().required("required"),
    eventDescription: yup.string().required("required"),
  });

  const setAddEventToggle = () => {
    setAddEvent(!isAddEvent);
  };

  const addEvent = async (values, onSubmitProps) => {
    const addEventResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    );
    const addEventLog = await addEventResponse.json();
    dispatch(setEvents({ events: addEventLog }));
    onSubmitProps.resetForm();
  };

  const handleFormAddEvent = async (values, onSubmitProps) => {
    await addEvent(values, onSubmitProps);
    setAddEventToggle();
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationsSchema,
    onSubmit: handleFormAddEvent,
  });

  const getEvents = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/event`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    dispatch(setEvents({ events: data }));
  };

  //Filters
  const [filterType, setFilterType] = useState("All");
  const [filterStartTime, setFilterStartTime] = useState(null);
  const [filterSeries, setFilterSeries] = useState("");

  const handleFilterChange = (event, filterType) => {
    switch (filterType) {
      case "type":
        setFilterType(event.target.value);
        break;
      case "starttime":
        setFilterStartTime(
          event.target.value ? new Date(event.target.value) : null
        );
        break;
      case "series":
        setFilterSeries(event.target.value);
        break;
      default:
        break;
    }
  };

  const filteredEvents = eventList.filter((event) => {
    const eventTypeFilter =
      filterType === "All" ? true : event.eventType === filterType;

    const startTimeFilter =
      filterStartTime === null ||
      new Date(event.eventStart).getTime() >= filterStartTime.getTime();

    const seriesFilter =
      !event.Series ||
      event.Series.toLowerCase().includes(filterSeries.toLowerCase());

    return eventTypeFilter && startTimeFilter && seriesFilter;
  });

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    // Sorting the events by eventStart time in ascending order
    const sortedEvents = [...events].sort((a, b) => {
      const timeA = new Date(a.eventStart).getTime();
      const timeB = new Date(b.eventStart).getTime();
      return timeA - timeB;
    });

    // Moving past events to the end of the array
    const currentTime = new Date().getTime();
    const pastEvents = sortedEvents.filter(
      (event) => new Date(event.eventEnd).getTime() < currentTime
    );
    const futureEvents = sortedEvents.filter(
      (event) => new Date(event.eventEnd).getTime() >= currentTime
    );
    const updatedEvents = [...futureEvents, ...pastEvents];

    setEventList(updatedEvents);
  }, [events]);

  const EventRow = (event) => {
    const isYes = Boolean(event.eventYes[loggedInUserId]);
    const isMaybe = Boolean(event.eventMaybe[loggedInUserId]);
    const isNo = Boolean(event.eventNo[loggedInUserId]);

    const patchYes = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/yes`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        }
      );
      const updatedEvent = await response.json();
    };

    const patchMaybe = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/maybe`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        }
      );
      const updatedEvent = await response.json();
    };

    const patchNo = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/no`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        }
      );
      const updatedEvent = await response.json();
    };

    return (
      <TableRow key={"Row_" + event._id}>
        <TableCell>
          {event.eventType === "Iracing" && (
            <SportsScoreOutlinedIcon fontSize="large" />
          )}
          {event.eventType === "Bday" && <CakeOutlinedIcon fontSize="large" />}
          {event.eventType === "Other" && (
            <MoreVertOutlinedIcon fontSize="large" />
          )}
        </TableCell>
        <TableCell>{event.eventTitle}</TableCell>
        <TableCell>{event.Series}</TableCell>
        <TableCell>
          {new Date(event.eventEnd) < timeNow ? (
            <Chip label="Beendet" size="small" color="primary" />
          ) : new Date(event.eventStart) <= timeNow &&
            new Date(event.eventEnd) >= timeNow ? (
            <Chip label="Läuft" size="small" color="error" />
          ) : (
            <Chip label="Kommend" size="small" color="success" />
          )}
        </TableCell>
        <TableCell>
          {new Date(event.eventStart).toLocaleString("de-De", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </TableCell>
        <TableCell>
          {new Date(event.eventEnd).toLocaleString("de-De", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </TableCell>
        <TableCell>{event.eventDescription}</TableCell>
        <TableCell>
          {
            <Badge
              badgeContent={Object.keys(event.eventYes).length}
              color="primary"
            >
              <IconButton onClick={patchYes}>
                {isYes ? (
                  <ThumbUpAltOutlinedIcon
                    sx={{ color: palette.primary.main }}
                  />
                ) : (
                  <ThumbUpAltOutlinedIcon />
                )}
              </IconButton>
            </Badge>
          }
        </TableCell>
        <TableCell>
          {
            <Badge
              badgeContent={Object.keys(event.eventMaybe).length}
              color="primary"
            >
              <IconButton onClick={patchMaybe}>
                {isMaybe ? (
                  <QuestionMarkOutlinedIcon
                    sx={{ color: palette.primary.main }}
                  />
                ) : (
                  <QuestionMarkOutlinedIcon />
                )}
              </IconButton>
            </Badge>
          }
        </TableCell>
        <TableCell>
          {
            <Badge
              badgeContent={Object.keys(event.eventNo).length}
              color="primary"
            >
              <IconButton onClick={patchNo}>
                {isNo ? (
                  <ThumbDownOffAltOutlinedIcon
                    sx={{ color: palette.primary.main }}
                  />
                ) : (
                  <ThumbDownOffAltOutlinedIcon />
                )}
              </IconButton>
            </Badge>
          }
        </TableCell>
        <TableCell>
          {
            <Badge
              badgeContent={Object.keys(event.eventComments).length}
              color="primary"
            >
              <MessageOutlinedIcon />
            </Badge>
          }
        </TableCell>
        <TableCell>
          {
            <FlexBetween>
              <CalendarDownloadButton
                eventName={event.eventTitle}
                startDate={event.eventStart}
                endDate={event.eventEnd}
              />
              <IconButton
                onClick={() => {
                  navigate(`/event/${event._id}`);
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </FlexBetween>
          }
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box height="100vh" display="flex" flexDirection="column">
          <Navbar />
          <Box
            display="flex"
            flexDirection="row"
            flex="flex-grow"
            height="100%"
          >
            {isNonMobileScreens && <Sidenavbar />}
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="auto"
            >
              <Box height="80vh">
                <Box>
                  <Box mt="0.5rem">
                    {/* Type Filter */}
                    <Select
                      value={filterType}
                      onChange={(event) => handleFilterChange(event, "type")}
                      displayEmpty
                      inputProps={{ "aria-label": "Type Filter" }}
                    >
                      <MenuItem value="All">All Events</MenuItem>
                      <MenuItem value="Iracing">Iracing</MenuItem>
                      <MenuItem value="Bday">Birthday</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>

                    {/* Start Time Filter */}
                    <TextField
                      label="Events nach dem"
                      type="datetime-local"
                      value={
                        filterStartTime
                          ? filterStartTime.toISOString().slice(0, -8)
                          : ""
                      }
                      onChange={(event) =>
                        handleFilterChange(event, "starttime")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />

                    {/* Series Filter */}
                    <TextField
                      label="Series Filter"
                      value={filterSeries}
                      onChange={(event) => handleFilterChange(event, "series")}
                    />
                  </Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Serie</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Start</TableCell>
                          <TableCell>Ende</TableCell>
                          <TableCell>Zusatz</TableCell>
                          <TableCell>Zusage</TableCell>
                          <TableCell>Vielleicht</TableCell>
                          <TableCell>Absage</TableCell>
                          <TableCell>Kommentare</TableCell>
                          <TableCell>Actionen</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredEvents.length
                          ? filteredEvents.map((event) => EventRow(event))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

                <Dialog open={isAddEvent} onClose={setAddEvent}>
                  <form onSubmit={formik.handleSubmit}>
                    <DialogContent>
                      <Box display="flex" flexDirection="column" gap="0.5rem">
                        <DialogContentText>
                          Füge ein Event hinzu.
                        </DialogContentText>
                        <TextField
                          label="Event"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.eventTitle}
                          name="eventTitle"
                          error={
                            Boolean(formik.touched.eventTitle) &&
                            Boolean(formik.errors.eventTitle)
                          }
                          helperText={
                            formik.touched.eventTitle &&
                            formik.errors.eventTitle
                          }
                          sx={{ gridColumn: "span 2" }}
                        />
                        <Select
                          labelId="Eventtyp"
                          name="eventType"
                          value={formik.values.eventType}
                          label="eventType"
                          onChange={formik.handleChange}
                        >
                          <MenuItem key="1" value={"Iracing"}>
                            Iracing
                          </MenuItem>
                          <MenuItem key="2" value={"Bday"}>
                            Geburtstag
                          </MenuItem>
                          <MenuItem key="3" value={"Other"}>
                            Anderes
                          </MenuItem>
                        </Select>
                        <DateTimePicker
                          label="Start"
                          id="eventStart"
                          value={dayjs(formik.values.eventStart)}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            formik.setFieldValue("eventStart", value);
                          }}
                          error={
                            Boolean(formik.touched.dob) &&
                            Boolean(formik.errors.dob)
                          }
                        />
                        <DateTimePicker
                          label="Ende"
                          id="eventEnd"
                          value={dayjs(formik.values.eventEnd)}
                          onBlur={formik.handleBlur}
                          onChange={(value) => {
                            formik.setFieldValue("eventEnd", value);
                          }}
                          error={
                            Boolean(formik.touched.dob) &&
                            Boolean(formik.errors.dob)
                          }
                        />
                        <TextField
                          label="Beschreibung"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.eventDescription}
                          name="eventDescription"
                          error={
                            Boolean(formik.touched.eventDescription) &&
                            Boolean(formik.errors.eventDescription)
                          }
                          helperText={
                            formik.touched.eventDescription &&
                            formik.errors.eventDescription
                          }
                          sx={{ gridColumn: "span 2" }}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={setAddEventToggle}>Abbrechen</Button>
                      <Button
                        type="submit"
                        sx={{
                          m: "2rem 0",
                          p: "1rem",
                          backgroundColor: palette.primary.main,
                          color: palette.background.alt,
                          "&:hover": { color: palette.primary.main },
                        }}
                      >
                        hinzufügen
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/** Admin Tools */}
      {user.role.includes("admin") ? (
        <Fab
          color="primary"
          aria-label="add"
          display="block"
          sx={{ position: "absolute", right: "2%", bottom: "2%" }}
          onClick={setAddEventToggle}
        >
          <AddIcon />
        </Fab>
      ) : null}
    </Box>
  );
};

export default EventListPage;
