import React from "react";
import { socket } from "../socket";
import { Button, ButtonGroup } from "@mui/material";

export function ConnectionManager({ isConnected }) {
  function connect() {
    socket.connect();
  }

  function disconnect() {
    socket.disconnect();
  }

  return (
    <ButtonGroup>
      <Button
        variant={isConnected ? "contained" : "outlined"}
        onClick={connect}
        size="small"
      >
        Connect
      </Button>
      <Button
        variant={isConnected ? "outlined" : "contained"}
        onClick={disconnect}
        size="small"
      >
        Disconnect
      </Button>
    </ButtonGroup>
  );
}
