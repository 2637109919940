import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slider,
  TextField,
} from "@mui/material";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useEffect, useState } from "react";
import { DiagramColors } from "theme";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setChartState } from "state/charts";
import { maxHeight } from "@mui/system";

function FuelScatter({ Logdata }) {
  const [sessionState, setSessionState] = useState(
    Logdata.SessionState.replace(/ +/g, "")
  );
  const AvgLapTime = Number(
    Logdata[sessionState].slice(-1)[0].lastvalues.AvgLapTime
  );

  function data(Stint) {
    var dataarr = [];

    for (let j = 0; j < Stint.Lapdata.length; j++) {
      if (Stint.Lapdata[j].Laptime > 0 && Stint.Lapdata[j].FuelCons > 0) {
        let datapoint = {};
        datapoint.x = Stint.Lapdata[j].Laptime.toFixed(3);
        datapoint.y = Stint.Lapdata[j].FuelCons.toFixed(2);
        dataarr.push(datapoint);
      }
    }
    return dataarr;
  }

  function Borders() {
    var min = 1000;
    var max = 0;

    for (let i = 0; i < Logdata[sessionState].length; i++) {
      if (Logdata[sessionState][i].FuelValues.Min < min) {
        min = Logdata[sessionState][i].FuelValues.Min;
      }
      if (Logdata[sessionState][i].FuelValues.Max > max) {
        max = Logdata[sessionState][i].FuelValues.Max;
      }
    }
    return [min, max];
  }

  //Chart Settings
  const dispatch = useDispatch();
  const size = useSelector((state) => state.charts.FuelScatter);
  const [isSetting, setIsSetting] = useState(false);

  const openDialog = () => {
    setIsSetting(true);
  };

  const closeDialog = () => {
    setIsSetting(false);
  };

  useEffect(() => {}, [Logdata, size]);

  return (
    <Box order={size.position}>
      <Dialog open={isSetting} onClose={closeDialog}>
        <DialogTitle>ChartSettings</DialogTitle>
        <DialogContent>
          <DialogContentText>Breite</DialogContentText>
          <Slider
            onChange={(_, value) => {
              dispatch(
                setChartState({
                  subCat: "FuelScatter",
                  param: "width",
                  value: value,
                })
              );
            }}
            aria-label="Chart-Size"
            value={size.width}
            valueLabelDisplay="auto"
            step={200}
            marks
            min={200}
            max={2000}
          />
          <DialogContentText>Höhe</DialogContentText>
          <Slider
            onChange={(_, value) => {
              dispatch(
                setChartState({
                  subCat: "FuelScatter",
                  param: "height",
                  value: value,
                })
              );
            }}
            aria-label="Chart-Size"
            value={size.height}
            valueLabelDisplay="auto"
            step={50}
            marks
            min={50}
            max={500}
          />
          <DialogContentText>Reihenfolge</DialogContentText>
          <TextField
            variant="outlined"
            type="number"
            value={size.position}
            onChange={(event) => {
              dispatch(
                setChartState({
                  subCat: "FuelScatter",
                  param: "position",
                  value: event.target.value,
                })
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader
          title="Rundenzeit vs. Verbrauch"
          action={
            <IconButton aria-label="settings" onClick={openDialog}>
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardContent>
          <ScatterChart
            width={size.width}
            height={size.height}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            {Logdata[sessionState].map((Stint) => (
              <Scatter
                name={Stint.Outing + "_Fuel"}
                data={data(Stint)}
                fill={DiagramColors[Stint.Outing - 1]}
                key={Stint.Outing + "_Fuel"}
              />
            ))}
            <XAxis
              type="number"
              dataKey="x"
              domain={([dataMin, dataMax]) => {
                return [
                  Number(AvgLapTime.toFixed(3)) - 2,
                  Number(AvgLapTime.toFixed(3)) + 2,
                ];
              }}
              interval="number"
              tickCount={10}
              allowDataOverflow={true}
            />
            <YAxis
              type="number"
              dataKey="y"
              name="y"
              domain={([dataMin, dataMax]) => {
                return [Borders()[0], Borders()[1]];
              }}
            />
          </ScatterChart>
        </CardContent>
      </Card>
    </Box>
  );
}

export default FuelScatter;
