import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DiagramColors } from "theme";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setChartState } from "state/charts";

function DeltaBar({ Logdata }) {
  const [sessionState, setSessionState] = useState(
    Logdata.SessionState.replace(/ +/g, "")
  );
  const [chartData, setChartData] = useState(null);
  const [mode, setMode] = useState(2);
  const [setTime, setSetTime] = useState(
    Logdata[sessionState].slice(-1)[0].lastvalues.AvgLapTime.toFixed(3)
  );

  //Chart Settings
  const dispatch = useDispatch();
  const size = useSelector((state) => state.charts.LapDelta);
  const [isSetting, setIsSetting] = useState(false);

  const openDialog = () => {
    setIsSetting(true);
  };

  const closeDialog = () => {
    setIsSetting(false);
  };

  const data = () => {
    var dataarr = [];
    var toDeltaTime = setTime;
    for (let x = 0; x < Logdata[sessionState].length; x++) {
      let stintnr = "Stint" + Logdata[sessionState][x].Outing;
      for (let j = 0; j < Logdata[sessionState][x].Lapdata.length; j++) {
        let datapoint = {};
        datapoint.name = Logdata[sessionState][x].Lapdata[j].Lap;
        datapoint[stintnr] =
          Logdata[sessionState][x].Lapdata[j].Laptime - toDeltaTime;
        dataarr.push(datapoint);
      }
    }
    setChartData(dataarr);
  };

  useEffect(() => {
    data();
  }, [setTime, size]);

  return (
    <Box order={size.position}>
      {Logdata === null ? null : (
        <>
          <Dialog open={isSetting} onClose={closeDialog}>
            <DialogTitle>ChartSettings</DialogTitle>
            <DialogContent>
              <DialogContentText>Breite</DialogContentText>
              <Slider
                onChange={(_, value) => {
                  dispatch(
                    setChartState({
                      subCat: "LapDelta",
                      param: "width",
                      value: value,
                    })
                  );
                }}
                aria-label="Chart-Size"
                value={size.width}
                valueLabelDisplay="auto"
                step={100}
                marks
                min={200}
                max={2000}
              />
              <DialogContentText>Höhe</DialogContentText>
              <Slider
                onChange={(_, value) => {
                  dispatch(
                    setChartState({
                      subCat: "LapDelta",
                      param: "height",
                      value: value,
                    })
                  );
                }}
                aria-label="Chart-Size"
                value={size.height}
                valueLabelDisplay="auto"
                step={50}
                marks
                min={50}
                max={500}
              />
              <DialogContentText>Reihenfolge</DialogContentText>
              <TextField
                variant="outlined"
                type="number"
                value={size.position}
                onChange={(event) => {
                  dispatch(
                    setChartState({
                      subCat: "LapDelta",
                      param: "position",
                      value: event.target.value,
                    })
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          <Card>
            <CardHeader
              title="Rundenzeit-Delta"
              action={
                <IconButton aria-label="settings" onClick={openDialog}>
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <CardContent>
              {chartData === null ? null : (
                <BarChart
                  width={size.width}
                  height={size.height}
                  data={chartData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis
                    type="number"
                    domain={[-2, 2]}
                    allowDataOverflow={true}
                  />
                  <Tooltip />
                  <Legend />

                  {Logdata[sessionState].length
                    ? Logdata[sessionState].map((stint) => (
                        <Bar
                          key={"Stint" + stint.Outing}
                          dataKey={"Stint" + stint.Outing}
                          stackId="a"
                          fill={DiagramColors[stint.Outing - 1]}
                        />
                      ))
                    : null}
                </BarChart>
              )}
              <Box mt="0.5rem" display="flex" justifyContent="center">
                <ButtonGroup>
                  <Button
                    key="mode1"
                    onClick={() => {
                      setMode(1);
                    }}
                    disabled
                    size="small"
                  >
                    Best
                  </Button>
                  <Button
                    key="mode2"
                    onClick={() => {
                      setMode(2);
                      var newTime = Logdata[sessionState]
                        .slice(-1)[0]
                        .lastvalues.AvgLapTime.toFixed(3);
                      setSetTime(newTime);
                    }}
                    variant={mode === 2 ? "contained" : "outlined"}
                    size="small"
                  >
                    Average
                  </Button>
                  <Button
                    key="mode3"
                    onClick={() => {
                      setMode(3);
                      var newTime = Logdata[sessionState]
                        .slice(-1)[0]
                        .Lapdata.slice(-1)[0]
                        .Laptime.toFixed(3);
                      setSetTime(newTime);
                    }}
                    variant={mode === 3 ? "contained" : "outlined"}
                    size="small"
                  >
                    Last
                  </Button>
                  <Button
                    key="mode4"
                    onClick={() => {
                      setMode(4);
                      var newTime = document.getElementById(
                        "outlined-controlled"
                      ).value;
                      setSetTime(newTime);
                    }}
                    variant={mode === 4 ? "contained" : "outlined"}
                    size="small"
                  >
                    Set
                  </Button>
                </ButtonGroup>
                <TextField
                  id="outlined-controlled"
                  variant="outlined"
                  size="small"
                  label="Set Time"
                  value={setTime}
                  onChange={(event) => {
                    setSetTime(event.target.value);
                  }}
                ></TextField>
              </Box>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
}

export default DeltaBar;
