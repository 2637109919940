import { FormControl } from "@mui/base";
import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slider,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { DiagramColors } from "theme";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { setChartState } from "state/charts";

function FuelHistogram({ Logdata }) {
  const [sessionState, setSessionState] = useState(
    Logdata.SessionState.replace(/ +/g, "")
  );
  const [chartData, setChartData] = useState(null);

  //Chart Settings
  const dispatch = useDispatch();
  const size = useSelector((state) => state.charts.FuelHisto);
  const [isSetting, setIsSetting] = useState(false);

  const openDialog = () => {
    setIsSetting(true);
  };

  const closeDialog = () => {
    setIsSetting(false);
  };

  const data = () => {
    var dataarr = [];
    for (let x = 0; x < Logdata[sessionState].length; x++) {
      let stintnr = "Stint" + Logdata[sessionState][x].Outing;
      for (let j = 0; j < Logdata[sessionState][x].Lapdata.length; j++) {
        if (
          Logdata[sessionState][x].Lap == 0 ||
          Logdata[sessionState][x].Lapdata[j].FuelCons == "--" ||
          isNaN(Logdata[sessionState][x].Lapdata[j].FuelCons) ||
          Logdata[sessionState][x].Lapdata[j].FuelCons === null
        ) {
        } else {
          let index = dataarr.findIndex(
            (item) =>
              item.name ===
              Math.round(Logdata[sessionState][x].Lapdata[j].FuelCons * 100) /
                100
          );
          if (index !== -1) {
            dataarr[index][stintnr] = (dataarr[index][stintnr] || 0) + 1;
          } else {
            let datapoint = {};
            datapoint.name =
              Math.round(Logdata[sessionState][x].Lapdata[j].FuelCons * 100) /
              100;
            datapoint[stintnr] = 1;
            dataarr.push(datapoint);
          }
        }
      }
    }
    let sortedData = dataarr.slice().sort((a, b) => a.name - b.name);
    let minName = sortedData[0].name * 100;
    let maxName = sortedData[sortedData.length - 1].name * 100;
    let filledData = [];
    for (let i = minName; i <= maxName; i++) {
      let dec = i / 100;
      const existingData = sortedData.find((item) => item.name === dec);
      if (existingData) {
        filledData.push(existingData);
      } else {
        filledData.push({ name: dec, Stint0: 0 });
      }
    }
    setChartData(filledData);
  };

  useEffect(() => {
    data();
  }, [Logdata, size]);

  return (
    <Box order={size.position}>
      {Logdata === null ? null : (
        <>
          <Dialog open={isSetting} onClose={closeDialog}>
            <DialogTitle>ChartSettings</DialogTitle>
            <DialogContent>
              <DialogContentText>Breite</DialogContentText>
              <Slider
                onChange={(_, value) => {
                  dispatch(
                    setChartState({
                      subCat: "FuelHisto",
                      param: "width",
                      value: value,
                    })
                  );
                }}
                aria-label="Chart-Size"
                value={size.width}
                valueLabelDisplay="auto"
                step={200}
                marks
                min={200}
                max={2000}
              />
              <DialogContentText>Höhe</DialogContentText>
              <Slider
                onChange={(_, value) => {
                  dispatch(
                    setChartState({
                      subCat: "FuelHisto",
                      param: "height",
                      value: value,
                    })
                  );
                }}
                aria-label="Chart-Size"
                value={size.height}
                valueLabelDisplay="auto"
                step={50}
                marks
                min={50}
                max={500}
              />
              <DialogContentText>Reihenfolge</DialogContentText>
              <TextField
                variant="outlined"
                type="number"
                value={size.position}
                onChange={(event) => {
                  dispatch(
                    setChartState({
                      subCat: "FuelHisto",
                      param: "position",
                      value: event.target.value,
                    })
                  );
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
          </Dialog>
          <Card>
            <CardHeader
              title="Verbrauch Histo"
              action={
                <IconButton aria-label="settings" onClick={openDialog}>
                  <MoreVertIcon />
                </IconButton>
              }
            />
            <CardContent>
              {chartData === null ? null : (
                <BarChart
                  width={size.width}
                  height={size.height}
                  data={chartData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />

                  {Logdata[sessionState].length
                    ? Logdata[sessionState].map((stint) => (
                        <Bar
                          key={"Stint" + stint.Outing}
                          dataKey={"Stint" + stint.Outing}
                          stackId="a"
                          fill={DiagramColors[stint.Outing - 1]}
                        />
                      ))
                    : null}
                </BarChart>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
}

export default FuelHistogram;
