import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";

const DiscordBot = ({ event }) => {
  const token = useSelector((state) => state.auth.token);
  const loggedInUserId = useSelector((state) => state.auth.user._id);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const updateFetch = async (type) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/discord/${type}`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    if (data.err) {
      setMessage("Fehler!");
      setType("error");
    } else {
      setMessage(data.message);
      setType("sucess");
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="2rem">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {type === "error" ? (
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {message}
          </Alert>
        ) : (
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        )}
      </Snackbar>
      <Card>
        <CardHeader title="Discord Bot Nachrichten" />
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Button onClick={() => updateFetch("remember")}>
              Erinnerung aussenden
            </Button>
            <Button
              onClick={() => {
                updateFetch("team");
              }}
            >
              Teams festgelegt
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DiscordBot;
