import {
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  useTheme,
  Typography,
  CardHeader,
  CardActions,
} from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "state";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import FlexBetweenTop from "components/FlexBetweenTop";

const ProfilForm = () => {
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormSubmit = async (values, onSubmitProps) => {
    const addEventResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/${user._id}/update`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      }
    );
    const addEventLog = await addEventResponse.json();
    onSubmitProps.resetForm();
    dispatch(
      setLogin({
        user: addEventLog,
        token: token,
      })
    );
  };

  const validationsSchemaInput = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    location: yup.string(),
    facebook: yup.string(),
    instagram: yup.string(),
    bday: yup.string(),
    twitch: yup.string(),
    youtube: yup.string(),
    twitter: yup.string(),
    irmain: yup.string(),
    irsmurf: yup.string(),
  });

  const initialValuesInput = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    location: user.location,
    bday: dayjs(user.bday),
    twitch: user.twitch,
    youtube: user.youtube,
    facebook: user.facebook,
    instagram: user.instagram,
    twitter: user.twiter,
    irmain: user.irmain,
    irsmurf: user.irsmurf,
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      validationSchema={validationsSchemaInput}
      initialValues={initialValuesInput}
      enableReinitialize="true"
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader title="Account anpassen" />
            <CardContent gap="1rem">
              <FlexBetweenTop>
                <Box display="flex" flexDirection="column" gap="1rem" m="1rem">
                  <Typography>Allgemeine Informationen</Typography>
                  <TextField
                    id="firstname"
                    label="Vorname"
                    variant="outlined"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      Boolean(touched.firstName) && Boolean(errors.firstName)
                    }
                    helperText={touched.firstName && errors.firstName}
                  />

                  <TextField
                    id="lastname"
                    label="Nachname"
                    variant="outlined"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      Boolean(touched.lastName) && Boolean(errors.lastName)
                    }
                    helperText={touched.lastName && errors.lastName}
                  />
                  <TextField
                    disabled
                    label="Email"
                    id="email"
                    variant="outlined"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    label="Wohnort"
                    id="location"
                    variant="outlined"
                    value={values.location}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      Boolean(touched.location) && Boolean(errors.location)
                    }
                    helperText={touched.location && errors.location}
                  />
                  <DatePicker
                    id="bday"
                    label="Geburtstag"
                    value={values.bday}
                    onBlur={handleBlur}
                    onChange={(value) => {
                      setFieldValue("bday", value);
                    }}
                    error={Boolean(touched.dob) && Boolean(errors.dob)}
                  />
                </Box>

                <Box display="flex" flexDirection="column" gap="1rem" m="1rem">
                  <Typography>Social Profiles</Typography>
                  <TextField
                    id="twitch"
                    label="Twitch"
                    variant="outlined"
                    value={values.twitch}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.twitch) && Boolean(errors.twitch)}
                    helperText={touched.twitch && errors.twitch}
                  />
                  <TextField
                    id="youtube"
                    label="YouTube"
                    variant="outlined"
                    value={values.youtube}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.youtube) && Boolean(errors.youtube)}
                    helperText={touched.youtube && errors.youtube}
                  />
                  <TextField
                    id="facebook"
                    label="Facebook"
                    variant="outlined"
                    value={values.facebook}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      Boolean(touched.facebook) && Boolean(errors.facebook)
                    }
                    helperText={touched.facebook && errors.facebook}
                  />
                  <TextField
                    id="instagram"
                    label="Instagram"
                    variant="outlined"
                    value={values.instagram}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      Boolean(touched.instagram) && Boolean(errors.instagram)
                    }
                    helperText={touched.instagram && errors.instagram}
                  />
                  <TextField
                    id="twitter"
                    label="Twitter"
                    variant="outlined"
                    value={values.twitter}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.twitter) && Boolean(errors.twitter)}
                    helperText={touched.twitter && errors.twitter}
                  />

                  <TextField
                    id="irmain"
                    label="Iracing Main (Id w/o #)"
                    variant="outlined"
                    value={values.irmain}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.irmain) && Boolean(errors.irmain)}
                    helperText={touched.irmain && errors.irmain}
                  />
                  <TextField
                    id="irsmurf"
                    label="Iracing Smurf (Id w/o #)"
                    variant="outlined"
                    value={values.irsmurf}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.iralt) && Boolean(errors.iralt)}
                    helperText={touched.iralt && errors.iralt}
                  />
                </Box>
              </FlexBetweenTop>
            </CardContent>
            <CardActions>
              {" "}
              <Button
                fullWidth
                type="submit"
                sx={{
                  m: "2rem 0",
                  p: "1rem",
                  backgroundColor: palette.primary.main,
                  color: palette.background.alt,
                  "&:hover": { color: palette.primary.main },
                }}
              >
                Save All
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default ProfilForm;
