import {
  Box,
  Divider,
  IconButton,
  Typography,
  useTheme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ThumbDownOffAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CalendarDownloadButton from "components/CalendarDownloadButton";

const EventSmallWidget = (eventIn) => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);
  const loggedInUserId = useSelector((state) => state.auth.user._id);
  // const isYes = eventYes.includes(loggedInUserId);
  // const isMaybe = eventMaybe.includes(loggedInUserId);
  // const isNo = eventNo.includes(loggedInUserId);
  const isNonMobileScreens = useMediaQuery("(min-width:1250px)");

  const [event, setEvent] = useState(eventIn.event);

  const [isYes, setIsYes] = useState(Boolean(event.eventYes[loggedInUserId]));
  const [isMaybe, setIsMaybe] = useState(
    Boolean(event.eventMaybe[loggedInUserId])
  );
  const [isNo, setIsNo] = useState(Boolean(event.eventNo[loggedInUserId]));
  const eventDate = new Date(event.eventStart);

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;

  const patchYes = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/yes`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId }),
      }
    );
    const updatedEvent = await response.json();
    setEvent(updatedEvent);
    setIsYes(!isYes);
  };

  const patchMaybe = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/maybe`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId }),
      }
    );
    const updatedEvent = await response.json();
    setEvent(updatedEvent);
    setIsMaybe(!isMaybe);
  };

  const patchNo = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/no`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId }),
      }
    );
    const updatedEvent = await response.json();
    setEvent(updatedEvent);
    setIsNo(!isNo);
  };

  return (
    <WidgetWrapper>
      <FlexBetween gap="1.0rem">
        <FlexBetween gap="1.0rem">
          <FlexBetween gap="1.0rem">
            <Box>
              {event.eventType === "Iracing" && (
                <SportsScoreOutlinedIcon fontSize="large" />
              )}
              {event.eventType === "Bday" && (
                <CakeOutlinedIcon fontSize="large" />
              )}
              {event.eventType === "Other" && (
                <MoreVertOutlinedIcon fontSize="large" />
              )}
            </Box>
            <FlexBetween flexDirection="column">
              <Typography fontWeight="500" fontSize="1.5rem">
                {("0" + eventDate.getDate()).slice(-2)}
              </Typography>
              <FlexBetween></FlexBetween>
              <Typography fontSize="0.5rem">
                {eventDate.toLocaleString("default", { month: "short" })}{" "}
                {eventDate.toLocaleString("default", { year: "numeric" })}
              </Typography>
              <Typography>
                {eventDate.toLocaleString("default", { weekday: "short" })}{" "}
                {eventDate.getHours()}:
                {("0" + eventDate.getMinutes()).slice(-2)}
              </Typography>
            </FlexBetween>
            <Box
              display="flex"
              justifyContent="center"
              gap="0.2rem"
              flexDirection="column"
              alignItems="start"
            >
              <Typography fontWeight="500" fontSize="1.2rem">
                {event.eventTitle}
              </Typography>
              <Divider variant="middle" />
              <Typography fontSize="0.5rem">
                {event.eventDescription}
              </Typography>
            </Box>
          </FlexBetween>
        </FlexBetween>
        <FlexBetween gap="4rem">
          <Box>{event.eventStatus}</Box>
          <FlexBetween gap="2.0rem">
            <FlexBetween
              gap="1.0em"
              flexDirection={isNonMobileScreens ? "row" : "column"}
            >
              <FlexBetween gap="0.5rem">
                <Tooltip title={Object.keys(event.eventYes)} placement="top">
                  <IconButton onClick={patchYes}>
                    {isYes ? (
                      <ThumbUpAltOutlinedIcon sx={{ color: primary }} />
                    ) : (
                      <ThumbUpAltOutlinedIcon />
                    )}
                  </IconButton>
                </Tooltip>
                <Typography>{Object.keys(event.eventYes).length}</Typography>
              </FlexBetween>
              <FlexBetween gap="0.5rem">
                <IconButton onClick={patchMaybe}>
                  {isMaybe ? (
                    <QuestionMarkOutlinedIcon sx={{ color: primary }} />
                  ) : (
                    <QuestionMarkOutlinedIcon />
                  )}
                </IconButton>
                <Typography>{Object.keys(event.eventMaybe).length}</Typography>
              </FlexBetween>
              <FlexBetween gap="0.5rem">
                <IconButton onClick={patchNo}>
                  {isNo ? (
                    <ThumbDownOffAltOutlinedIcon sx={{ color: primary }} />
                  ) : (
                    <ThumbDownOffAltOutlinedIcon />
                  )}
                </IconButton>

                <Typography>{Object.keys(event.eventNo).length}</Typography>
              </FlexBetween>
              <FlexBetween gap="0.5rem">
                <MessageOutlinedIcon />
                <Typography>{event.eventComments.length}</Typography>
              </FlexBetween>
            </FlexBetween>
            <FlexBetween flexDirection={isNonMobileScreens ? "row" : "column"}>
              <CalendarDownloadButton
                eventName={event.eventTitle}
                startDate={event.eventStart}
                endDate={event.eventEnd}
              />
              <IconButton
                onClick={() => {
                  navigate(`/event/${event._id}`);
                }}
              >
                <InfoOutlinedIcon />
              </IconButton>
            </FlexBetween>
          </FlexBetween>
        </FlexBetween>
      </FlexBetween>
    </WidgetWrapper>
  );
};

export default EventSmallWidget;
