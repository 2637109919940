import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setEvent } from "state";

const Availibility = ({ event }) => {
  const [userList, setUserList] = useState({});
  const start = new Date(event.eventStart);
  const end = new Date(event.eventEnd);
  const me = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const loggedInUserId = useSelector((state) => state.auth.user._id);
  const [meAvail, setMeAvail] = useState([]);
  const [isMeEvent, setIsMeEvent] = useState(false);

  function mergeUserList() {
    var obj = {
      ...event.eventYes,
      ...event.eventMaybe,
    };
    setUserList(obj);
  }

  function checkUserAvailibity() {
    if (Object.keys(userList).length === 0) {
    } else {
      if (loggedInUserId in userList) {
        setIsMeEvent(true);
        if ("Availibility" in userList[loggedInUserId]) {
          setMeAvail(userList[loggedInUserId].Availibility);
        } else {
          const dur = (end - start) / 1000 / 60 / 60;
          let content = [];

          for (let i = -1; i <= dur + 1; i++) {
            let time = new Date(start.getTime() + i * 3600000);
            let timeform =
              time.getHours() +
              ":" +
              String(time.getMinutes()).padStart(2, "0");
            content.push({ timeform: timeform, avail: false, time: time });
          }
          setMeAvail(content);
        }
      }
    }
  }

  useEffect(() => {
    mergeUserList();
    checkUserAvailibity();
  }, [event]);

  useEffect(() => {
    checkUserAvailibity();
  }, [userList]);

  async function patchAvaibility() {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/availibility/${loggedInUserId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ availibility: meAvail }),
      }
    );
    const updatedPost = await response.json();
    setEvent(updatedPost);
  }

  const TimeBtn = ({ user, time, type, state }) => {
    const [flag, setFlag] = useState(state);

    function changeTimeBtn() {
      let changetime = meAvail.findIndex((x) => x.timeform === time);
      meAvail[changetime].avail = !flag;
      let newAvail = meAvail;
      setMeAvail(newAvail);
      setFlag(!flag);
    }

    return (
      <Button
        key={user + "_me_" + time}
        variant={type}
        color={flag ? "success" : "error"}
        onClick={changeTimeBtn}
      >
        {time}
      </Button>
    );
  };

  const TimeBtnOthers = ({ user, time, type, state }) => {
    const [flag, setFlag] = useState(state);

    return (
      <Button
        key={user + "_me_" + time}
        variant={type}
        size={"small"}
        color={flag ? "success" : "error"}
      >
        {time}
      </Button>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap="2rem">
      {isMeEvent ? (
        <Card>
          <CardHeader title="Deine Verfügbarkeiten" />
          <CardContent>
            {meAvail.map((ti) => (
              <TimeBtn
                user={loggedInUserId}
                key={"box_Btn_Change_" + loggedInUserId + "-" + ti.time}
                time={ti.timeform}
                type={
                  new Date(ti.time) < start || new Date(ti.time) > end
                    ? "outlined"
                    : "contained"
                }
                state={ti.avail}
              />
            ))}
          </CardContent>
          <CardActions>
            <Button onClick={patchAvaibility}>Save</Button>
          </CardActions>
        </Card>
      ) : null}
      <Card>
        <CardHeader title="Teamverfügbarkeiten" />
        <CardContent>
          {Object.keys(userList).length
            ? Object.keys(userList).map((user) => (
                <Box key={"box_" + user} mt="0.5rem" mb="0.5rem">
                  <Typography
                    key={"user_key_" + user}
                    component={"span"}
                    variant={"h5"}
                    mb="0.5rem"
                  >
                    {userList[user].firstName + " " + userList[user].lastName}
                  </Typography>
                  {userList[user].Availibility ? (
                    <Box key={"box_2_" + user} mt="0.25rem" mb="0.5rem">
                      {userList[user].Availibility.map((ti) => (
                        <TimeBtnOthers
                          user={
                            userList[user].firstName +
                            " " +
                            userList[user].lastName
                          }
                          time={ti.timeform}
                          type={
                            new Date(ti.time) < start || new Date(ti.time) > end
                              ? "outlined"
                              : "contained"
                          }
                          state={ti.avail}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Typography mb="0.5rem" variant="subtitle2">
                      noch nichts festgelegt
                    </Typography>
                  )}
                  <Divider />
                </Box>
              ))
            : null}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Availibility;
