import DownloadIcon from "@mui/icons-material/Download";
import { IconButton } from "@mui/material";

const CalendarDownloadButton = ({ startDate, endDate, eventName }) => {
  const downloadCalendar = () => {
    const calendarContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${eventName}
DTSTART:${startDate.replace(/-|:|\.\d+/g, "")}
DTEND:${endDate.replace(/-|:|\.\d+/g, "")}
DTSTAMP:20240608T182107Z
UID:1717870867941-Test
DESCRIPTION:
LOCATION:Online
ORGANIZER:VOLTA Simracing
STATUS:CONFIRMED
PRIORITY:0
END:VEVENT
END:VCALENDAR
`;

    // Create a Blob from the calendar content
    const blob = new Blob([calendarContent], { type: "text/calendar" });
    const url = URL.createObjectURL(blob);

    // Create a link and click it to trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${eventName}.ics`;
    a.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  return (
    <IconButton onClick={downloadCalendar}>
      <DownloadIcon />
    </IconButton>
  );
};

export default CalendarDownloadButton;
