import { useTheme } from "@emotion/react";
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Box } from "@mui/system";
import FlexBetween from "components/FlexBetween";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";

const ExternPage = () => {
  const navigate = useNavigate();
  const [isImpressum, setIsImpressum] = useState(false);

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;

  const setCloseImpressum = () => {
    setIsImpressum(false);
  };

  const setOpenImpressum = () => {
    setIsImpressum(true);
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <Typography
              fontFamily="HardRace"
              fontWeight="bold"
              fontSize="clamp(1rem, 2rem, 2.25rem)"
              lineHeight="1.5rem"
              color={dark}
              component="div"
              sx={{ flexGrow: 1 }}
            >
              VOLTA
            </Typography>
            <Button color="inherit" onClick={() => navigate("/login")}>
              Interner Login
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          backgroundImage: "url(/assets/background_extern.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "80vh",
          width: "100%",
        }}
      >
        <Box
          display="block"
          sx={{
            position: "absolute",
            zindex: 100,
            top: "25vh",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          gap="1.75rem"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap="0.75rem"
          >
            <Typography
              fontFamily="HardRace"
              fontWeight="bold"
              fontSize="clamp(4rem, 5rem, 4rem)"
              lineHeight="1.5rem"
              color={dark}
            >
              VOLTA
            </Typography>
            <Typography
              fontFamily="HardRace"
              fontWeight="bold"
              fontSize="clamp(1.5rem, 3rem, 2.5rem)"
              lineHeight="1.5rem"
              color={dark}
            >
              Simracing
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          display="flex"
          mt="2rem"
          mb="2rem"
          flexDirection="column"
          gap="1rem"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h1">Über uns</Typography>
          <Typography variant="h5">
            Volta Simracing ist 2024 nach der Auflösung von Riley SimRacing
            entstanden. Als deutschsprachiges Team liegt der Fokus auf der
            Simulation Iracing und der Teilnahme an deutschen und
            internationalen Serien.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        height="50px"
        justifyContent="center"
        alignItems="center"
        sx={{ bgcolor: theme.palette.background.alt }}
      >
        <Box display="flex">
          <Typography>
            All copyrights reserved © 2024 - Volta Simracing
          </Typography>
          <Typography> / </Typography>
          <Link onClick={setOpenImpressum}>Impressum</Link>
        </Box>
        <Dialog open={isImpressum} onClose={setCloseImpressum}>
          <DialogContent>
            <h1>Impressum</h1>

            <p>
              Dominik Pusch
              <br />
              Breiten&auml;ckergasse 2/2/18
              <br />
              2483 Ebreichsdorf
            </p>

            <h2>Kontakt</h2>
            <p>E-Mail: info@voltasimracing.de</p>

            <h2>Redaktionell verantwortlich</h2>
            <p>
              Dominik Pusch
              <br />
              Breiten&auml;ckergasse 2/2/18
              <br />
              A-2483 Ebreichsdorf
            </p>

            <p>
              Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
            </p>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default ExternPage;
