import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import { useSelector } from "react-redux";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";

const TeamPage = () => {
  const [team, setTeam] = useState(null);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);

  const getTeam = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/team`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setTeam(data);
  };

  useEffect(() => {
    getTeam();
  }, []);

  if (!team) return null;

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box height="100vh" display="flex" flexDirection="column">
          <Navbar />
          <Box
            display="flex"
            flexDirection="row"
            flex="flex-grow"
            height="100%"
          >
            <Sidenavbar />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="scroll"
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={3}
                mt="1rem"
              >
                {team.map((mem) => (
                  <Grid item key={mem._id + "_grid"}>
                    <UserWidget
                      key={mem._id}
                      userId={mem._id}
                      picturePath={mem.picturePath}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TeamPage;
