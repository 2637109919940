import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Icon,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Navbar from "scenes/navbar";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import FuelHistogram from "../SessionPage/FuelHistogram";
import FuelTable from "../SessionPage/FuelTable";
import FuelArea from "./FuelArea";
import StintTable from "./StintTable";
import LapArea from "./LapArea";
import FuelScatter from "./FuelScatter";
import DataField from "./DataField";
import DeltaBar from "./DeltaBar";
import { ConnectionManager } from "components/ConnectionManager";
import { socket } from "socket";
import FuelDelta from "./FuelDelta";
import FlexBetweenTop from "components/FlexBetweenTop";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimerIcon from "@mui/icons-material/Timer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LapHistogram from "./LapHistogram";
import PropTypes from "prop-types";
import TimingTab from "./TimingTab";
import testdata from "./data.json";
import { Button } from "@mui/base";
import LiveTelemetry from "./LiveTelemetry";
import { Container } from "@mui/system";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ left: 0, right: 0 }}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SessionPage = () => {
  const { sessionId, teamName } = useParams();
  const token = useSelector((state) => state.auth.token);

  const [session, setSessions] = useState(null);
  const Logdata = session === null ? null : session.Logdata;
  const [dashdata, setDashdata] = useState(null);

  const sessionState =
    Logdata === null || Logdata === undefined
      ? null
      : Logdata.SessionState === -1
      ? null
      : "SessionState" in Logdata
      ? Logdata.SessionState.replace(/ +/g, "")
      : null;
  const Stint =
    sessionState === null ? null : Logdata[sessionState].slice(-1)[0];
  const Lap = Stint === null ? null : Stint.Lapdata.slice(-1)[0];

  //Socket.io
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);
  const [lastEvent, setLastEvent] = useState(null);
  const [lastDash, setLastDash] = useState(null);
  const lisinigOn = "update " + sessionId + "/" + teamName;
  const lisinigDash = "update Dash " + sessionId + "/" + teamName;

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setActiveTab(newValue);
  };

  const dataFetch = async () => {
    const data = await (
      await fetch(
        `${process.env.REACT_APP_SERVER_URL}/telemetrie/sessions/${sessionId}/${teamName}`,
        {
          methode: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
    ).json();
    setSessions(data);
  };

  function prettyOutputSec(input) {
    let hours = Math.floor(input / 3600);
    let minutes = Math.floor((input - hours * 3600) / 60);
    let secounds = Math.floor(input - hours * 3600 - minutes * 60);
    if (hours > 0) {
      var string =
        hours +
        ":" +
        ("0" + minutes).slice(-2) +
        ":" +
        ("0" + secounds).slice(-2);
    } else {
      var string = ("0" + minutes).slice(-2) + ":" + ("0" + secounds).slice(-2);
    }
    return string;
  }

  function prettyOutputLap(input) {
    let minutes = Math.floor(input / 60);
    let secounds = (Math.floor((input - minutes * 60) * 1000) / 1000).toFixed(
      3
    );
    var string = minutes + ":" + ("0" + secounds).slice(-6);
    return string;
  }

  //for testing
  const saveFile = async (blob) => {
    const a = document.createElement("a");
    a.download = "data.json";
    a.href = URL.createObjectURL(blob);
    a.addEventListener("click", (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const loadtestdata = () => {
    setDashdata(testdata);
  };

  useEffect(() => {
    dataFetch();
  }, []);

  //Socket.io useEffect
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onFooEvent(value) {
      setFooEvents((previous) => [...previous, value]);
      setLastEvent(new Date().toLocaleTimeString());
    }

    function onNewLogdata(value) {
      var newSession = { Logdata: JSON.parse(value) };
      setSessions(newSession);
      setLastEvent(new Date().toLocaleTimeString());
    }

    function onNewDashdata(value) {
      var newDash = JSON.parse(value);
      setDashdata(newDash);
      setLastDash(new Date().toLocaleTimeString());
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("foo", onFooEvent);
    socket.on(lisinigOn, onNewLogdata);
    socket.on(lisinigDash, onNewDashdata);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("foo", onFooEvent);
      socket.off(lisinigOn, onNewLogdata);
      socket.off(lisinigDash, onNewDashdata);
    };
  }, []);

  return (
    <Box height="100vh" display="flex" flexDirection="column" overflow="hidden">
      <Navbar />
      <Box display="flex" flexDirection="row" flex="flex-grow" height="100%">
        <Sidenavbar />
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          overflow="scroll"
        >
          <Box p="1rem">
            <Box>
              {session === null ? (
                <Typography>Upps...</Typography>
              ) : (
                <>
                  <Box>
                    <FlexBetween flexDirection="row">
                      <Box>
                        <Typography variant="h2" mb="1rem">
                          {Logdata.Team}
                        </Typography>
                        <Divider />
                        <Box mt="1rem">
                          <Typography variant="h5">{Logdata.Track}</Typography>
                          <Typography variant="h5">{Logdata.Car}</Typography>
                          <Typography variant="h5">
                            {Logdata.Weather.String}
                          </Typography>
                        </Box>
                      </Box>
                      <Card>
                        <CardHeader title="Live Update Status" />
                        <CardContent>
                          <ConnectionManager isConnected={isConnected} />
                          <FlexBetween>
                            <Typography>Last Update:</Typography>
                            <Typography>
                              {lastEvent === null ? "No LiveData" : lastEvent}
                            </Typography>
                          </FlexBetween>
                          <FlexBetween>
                            <Typography>Dash:</Typography>
                            <Typography>
                              {lastDash === null ? "No LiveData" : lastDash}
                            </Typography>
                          </FlexBetween>
                          <Button
                            onClick={() =>
                              saveFile(
                                new Blob([JSON.stringify(dashdata, null, 2)], {
                                  type: "application/json",
                                })
                              )
                            }
                          >
                            Save LiveData for Testing
                          </Button>
                          <Button onClick={loadtestdata}>Load Testdata</Button>
                        </CardContent>
                      </Card>
                    </FlexBetween>
                    <>
                      <Tabs value={activeTab} onChange={handleChangeTabs}>
                        <Tab label="Zusammenfassung" />
                        <Tab label="RundenTabelle" />
                        <Tab label="Timing" />
                        <Tab label="Telemetry" />
                      </Tabs>
                      <CustomTabPanel value={activeTab} index={0}>
                        {sessionState === null ? (
                          <Typography>Waiting for Laps ....</Typography>
                        ) : (
                          <Box width="100%">
                            <FlexBetween pt={"1rem"} width={"100%"}>
                              <Card>
                                <CardHeader title="Verbleibender Tankinhalt" />
                                <CardContent>
                                  <FlexBetween
                                    display="flex"
                                    flexDirection="row"
                                    marginRight="1rem"
                                    marginLeft="1rem"
                                    gap="1rem"
                                  >
                                    <LocalGasStationIcon fontSize="large" />
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="baseline"
                                      gap="0.25rem"
                                      marginRight="1rem"
                                    >
                                      <Typography variant="h1">
                                        {Lap.FuelLevel.toFixed(1)}
                                      </Typography>
                                      <Typography variant="h5">
                                        {Logdata.Unit === "L" ? "Liter" : "kg"}
                                      </Typography>
                                    </Box>
                                  </FlexBetween>
                                </CardContent>
                              </Card>
                              <Card>
                                <CardHeader title="Runden" />
                                <CardContent>
                                  <FlexBetween
                                    display="flex"
                                    flexDirection="row"
                                    marginRight="1rem"
                                    marginLeft="1rem"
                                    gap="1rem"
                                  >
                                    <LoopIcon fontSize="large" />
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="baseline"
                                      gap="0.25rem"
                                      marginRight="1rem"
                                    >
                                      <Typography variant="h1">
                                        {Lap.Lap}
                                      </Typography>
                                      <Typography variant="h4">
                                        {"/" + (Lap.Lap + Lap.LapsRemain)}
                                      </Typography>
                                    </Box>
                                  </FlexBetween>
                                </CardContent>
                              </Card>
                              <Card>
                                <CardHeader title="Verbleibende Zeit" />
                                <CardContent>
                                  <FlexBetween
                                    display="flex"
                                    flexDirection="row"
                                    marginRight="1rem"
                                    marginLeft="1rem"
                                    gap="1rem"
                                  >
                                    <AccessTimeIcon fontSize="large" />
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="baseline"
                                      gap="0.25rem"
                                      marginRight="1rem"
                                    >
                                      <Typography variant="h1">
                                        {prettyOutputSec(Lap.TimeRemain)}
                                      </Typography>
                                    </Box>
                                  </FlexBetween>
                                </CardContent>
                              </Card>
                              <Card>
                                <CardHeader title="Stintinfo" />
                                <CardContent>
                                  <FlexBetween
                                    display="flex"
                                    flexDirection="row"
                                    marginRight="1rem"
                                    marginLeft="1rem"
                                    gap="1rem"
                                  >
                                    <DirectionsCarIcon fontSize="large" />
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="baseline"
                                      gap="0.25rem"
                                      marginRight="1rem"
                                    >
                                      <Typography variant="h1">
                                        {Lap.StintLap}
                                      </Typography>
                                      <Typography variant="h4">
                                        {"/" +
                                          (Lap.StintLap +
                                            Math.floor(
                                              Lap.FuelLevel / Lap.FuelCons
                                            ))}
                                      </Typography>
                                    </Box>
                                  </FlexBetween>
                                </CardContent>
                              </Card>
                              <Card>
                                <CardHeader title="Letzte Rundenzeit" />
                                <CardContent>
                                  <FlexBetween
                                    display="flex"
                                    flexDirection="row"
                                    marginRight="1rem"
                                    marginLeft="1rem"
                                    gap="1rem"
                                  >
                                    <TimerIcon fontSize="large" />
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="center"
                                      gap="0.25rem"
                                      marginRight="1rem"
                                    >
                                      <Typography variant="h1">
                                        {prettyOutputLap(Lap.Laptime)}
                                      </Typography>
                                    </Box>
                                  </FlexBetween>
                                </CardContent>
                              </Card>
                            </FlexBetween>
                            <FlexBetweenTop
                              flexWrap={"wrap"}
                              gap="2rem"
                              mt="2rem"
                            >
                              <LapArea key={"a"} Logdata={Logdata} />
                              <DeltaBar key={"b"} Logdata={Logdata} />
                              <FuelScatter key={"c"} Logdata={Logdata} />
                              <FuelHistogram key={"d"} Logdata={Logdata} />
                              <FuelDelta key={"e"} Logdata={Logdata} />
                              <FuelTable key={"f"} Stint={Stint} />
                              <FuelArea key={"g"} Logdata={Logdata} />
                              <LapHistogram key={"h"} Logdata={Logdata} />
                            </FlexBetweenTop>
                          </Box>
                        )}
                      </CustomTabPanel>
                      <CustomTabPanel value={activeTab} index={2}>
                        {dashdata === null ? (
                          <Typography>
                            Keine Livedaten verfügbar ....
                          </Typography>
                        ) : (
                          <TimingTab data={dashdata} />
                        )}
                      </CustomTabPanel>
                      <CustomTabPanel value={activeTab} index={1}>
                        {sessionState === null ? (
                          <Typography>Waiting for Laps ....</Typography>
                        ) : (
                          <>
                            <Box>
                              {Logdata != null
                                ? Logdata.OfflineTesting &&
                                  Logdata.OfflineTesting.length
                                  ? Logdata.OfflineTesting.map(
                                      (Stint, index) => (
                                        <Box>
                                          {Stint ? (
                                            <Box key={index} p="0.5rem">
                                              <StintTable Stint={Stint} />
                                            </Box>
                                          ) : null}
                                        </Box>
                                      )
                                    )
                                  : null
                                : null}
                            </Box>

                            <Box>
                              {Logdata != null
                                ? Logdata.Practice && Logdata.Practice.length
                                  ? Logdata.Practice.map((Stint, index) => (
                                      <Box key={index + "_PBox"}>
                                        {Stint ? (
                                          <Box key={index} p="0.5rem">
                                            <StintTable Stint={Stint} />
                                          </Box>
                                        ) : null}
                                      </Box>
                                    ))
                                  : null
                                : null}
                            </Box>
                            <Box>
                              {Logdata != null
                                ? Logdata.LoneQualify &&
                                  Logdata.LoneQualify.length
                                  ? Logdata.LoneQualify.map((Stint, index) => (
                                      <Box key={index + "_LQBox"}>
                                        {Stint ? (
                                          <Box key={index} p="0.5rem">
                                            <StintTable Stint={Stint} />
                                          </Box>
                                        ) : null}
                                      </Box>
                                    ))
                                  : null
                                : null}
                            </Box>
                            <Box>
                              {Logdata != null
                                ? Logdata.Qualify && Logdata.Qualify.length
                                  ? Logdata.Qualify.map((Stint, index) => (
                                      <Box key={index + "_QBox"}>
                                        {Stint ? (
                                          <Box key={index} p="0.5rem">
                                            <StintTable Stint={Stint} />
                                          </Box>
                                        ) : null}
                                      </Box>
                                    ))
                                  : null
                                : null}
                            </Box>
                            <Box>
                              {Logdata != null
                                ? Logdata.Race && Logdata.Race.length
                                  ? Logdata.Race.map((Stint, index) => (
                                      <Box key={index + "_RBox"}>
                                        {Stint ? (
                                          <Box key={index} p="0.5rem">
                                            <StintTable Stint={Stint} />
                                          </Box>
                                        ) : null}
                                      </Box>
                                    ))
                                  : null
                                : null}
                            </Box>
                          </>
                        )}
                      </CustomTabPanel>
                      <CustomTabPanel value={activeTab} index={3}>
                        {dashdata === null ? (
                          <Typography>
                            Keine Livedaten verfügbar ....
                          </Typography>
                        ) : (
                          <LiveTelemetry data={dashdata} />
                        )}
                      </CustomTabPanel>
                    </>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SessionPage;
