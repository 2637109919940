import { Box, Typography, useTheme } from "@mui/material";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState } from "react";

const ParticipentsWidget = ({ type, userList }) => {
  const { palette } = useTheme();
  const [users, setUsers] = useState(userList);

  useEffect(() => {
    setUsers(userList);
  }, [userList]);

  return (
    <WidgetWrapper width="100%" heigth="100%">
      <Box width="100%" height="100%">
        <Typography
          color={palette.neutral.dark}
          variant="h5"
          fontWeight="500"
          sx={{ mb: "1.5rem" }}
        >
          {type}
        </Typography>
        <Box display="flex" flexDirection="column" gap="1.5rem">
          {Object.keys(users).length ? (
            Object.keys(users).map((user) => (
              <Friend key={users[user]._id} friendId={users[user]._id} />
            ))
          ) : (
            <Typography>Keiner hier</Typography>
          )}
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default ParticipentsWidget;
