import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import LoopIcon from "@mui/icons-material/Loop";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimerIcon from "@mui/icons-material/Timer";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { CheckBox } from "@mui/icons-material";

function LiveTelemetry({ data }) {
  const FocusIdx = data.c;
  const FocusDriver = data.d[FocusIdx];

  function prettyOutputLap(input) {
    let minutes = Math.floor(input / 60);
    let secounds = (Math.floor((input - minutes * 60) * 1000) / 1000).toFixed(
      3
    );
    var string = minutes + ":" + ("0" + secounds).slice(-6);
    return string;
  }

  function prettyOutputSec(input) {
    let hours = Math.floor(input / 3600);
    let minutes = Math.floor((input - hours * 3600) / 60);
    let secounds = Math.floor(input - hours * 3600 - minutes * 60);
    if (hours > 0) {
      var string =
        hours +
        ":" +
        ("0" + minutes).slice(-2) +
        ":" +
        ("0" + secounds).slice(-2);
    } else {
      var string = ("0" + minutes).slice(-2) + ":" + ("0" + secounds).slice(-2);
    }
    return string;
  }

  useEffect(() => {}, [data]);

  return (
    <Box>
      <Box>
        <FlexBetween pt={"1rem"} flexWrap="wrap">
          <Card>
            <CardHeader title="Verbleibender Tankinhalt" />
            <CardContent>
              <FlexBetween
                display="flex"
                flexDirection="row"
                marginRight="1rem"
                marginLeft="1rem"
                gap="1rem"
              >
                <LocalGasStationIcon fontSize="large" />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  gap="0.25rem"
                  marginRight="1rem"
                >
                  <Typography variant="h1">{data.f.toFixed(1)}</Typography>
                  <Typography variant="h5">
                    {data.jrt_fuel_units === 0 ? "Liter" : "kg"}
                  </Typography>
                </Box>
              </FlexBetween>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Runden" />
            <CardContent>
              <FlexBetween
                display="flex"
                flexDirection="row"
                marginRight="1rem"
                marginLeft="1rem"
                gap="1rem"
              >
                <LoopIcon fontSize="large" />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  gap="0.25rem"
                  marginRight="1rem"
                >
                  <Typography variant="h1">{data.me_lc}</Typography>
                  <Typography variant="h4">
                    {"/" + (data.me_lc + Math.ceil(data.lr))}
                  </Typography>
                </Box>
              </FlexBetween>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Verbleibende Zeit" />
            <CardContent>
              <FlexBetween
                display="flex"
                flexDirection="row"
                marginRight="1rem"
                marginLeft="1rem"
                gap="1rem"
              >
                <AccessTimeIcon fontSize="large" />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  gap="0.25rem"
                  marginRight="1rem"
                >
                  <Typography variant="h1">
                    {prettyOutputSec(data.tr)}
                  </Typography>
                </Box>
              </FlexBetween>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Stintinfo" />
            <CardContent>
              <FlexBetween
                display="flex"
                flexDirection="row"
                marginRight="1rem"
                marginLeft="1rem"
                gap="1rem"
              >
                <DirectionsCarIcon fontSize="large" />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="baseline"
                  gap="0.25rem"
                  marginRight="1rem"
                >
                  <Typography variant="h1">{data.stm}</Typography>
                  <Typography variant="h4">
                    {"/" + data.target_stint_nblaps}
                  </Typography>
                </Box>
              </FlexBetween>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Letzte Rundenzeit" />
            <CardContent>
              <FlexBetween
                display="flex"
                flexDirection="row"
                marginRight="1rem"
                marginLeft="1rem"
                gap="1rem"
              >
                <TimerIcon fontSize="large" />
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="0.25rem"
                  marginRight="1rem"
                >
                  <Typography variant="h1">
                    {prettyOutputLap(data.l)}
                  </Typography>
                </Box>
              </FlexBetween>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Letzter Boxenstop" />
            <CardContent>
              <Box>
                <FlexBetween gap="1rem">
                  <Box>
                    <Typography>Links Vorne</Typography>
                    <FlexBetween gap="0.5rem">
                      <Typography>{data.LFwearL + "%"}</Typography>
                      <Typography>{data.LFwearM + "%"}</Typography>
                      <Typography>{data.LFwearR + "%"}</Typography>
                    </FlexBetween>
                  </Box>
                  <Box>
                    <Typography>Rechts Vorne</Typography>
                    <FlexBetween gap="0.5rem">
                      <Typography>{data.RFwearL + "%"}</Typography>
                      <Typography>{data.RFwearM + "%"}</Typography>
                      <Typography>{data.RFwearR + "%"}</Typography>
                    </FlexBetween>
                  </Box>
                </FlexBetween>
                <FlexBetween gap="1rem" mb="0.5rem">
                  <Box>
                    <Typography>Links Hinten</Typography>
                    <FlexBetween gap="0.5rem">
                      <Typography>{data.LRwearL + "%"}</Typography>
                      <Typography>{data.LRwearM + "%"}</Typography>
                      <Typography>{data.LRwearR + "%"}</Typography>
                    </FlexBetween>
                  </Box>
                  <Box>
                    <Typography>Rechts Hinten</Typography>
                    <FlexBetween gap="0.5rem">
                      <Typography>{data.RRwearL + "%"}</Typography>
                      <Typography>{data.RRwearM + "%"}</Typography>
                      <Typography>{data.RRwearR + "%"}</Typography>
                    </FlexBetween>
                  </Box>
                </FlexBetween>
                <Divider />
                <FlexBetween mt="0.5rem">
                  <Typography>Last Stop</Typography>
                  <Typography>{FocusDriver.rt + " s"}</Typography>
                </FlexBetween>
              </Box>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Nächster Pitstop" />
            <CardContent>
              <FlexBetween>
                <Typography>Refuel</Typography>
                <CheckBox
                  checked={data.iracing_fuel_box === 1 ? true : false}
                />
              </FlexBetween>
              <FlexBetween>
                <Typography>{"Fuel Add"}</Typography>
                <Typography>{data.iracing_fuel_add}</Typography>
              </FlexBetween>

              <FlexBetween mt="0.5rem">
                <Typography>Windshield</Typography>
                <CheckBox
                  checked={data.iracing_windshield_box === 1 ? true : false}
                />
              </FlexBetween>
              <FlexBetween mt="0.5rem">
                <CheckBox
                  checked={data.iracing_lf_tire_box === 1 ? true : false}
                />
                <Typography>LF</Typography>
                <Typography>RF</Typography>
                <CheckBox
                  checked={data.iracing_rf_tire_box === 1 ? true : false}
                />
              </FlexBetween>
              <FlexBetween>
                <CheckBox
                  checked={data.iracing_lr_tire_box === 1 ? true : false}
                />
                <Typography>LR</Typography>
                <Typography>RR</Typography>
                <CheckBox
                  checked={data.iracing_rr_tire_box === 1 ? true : false}
                />
              </FlexBetween>
              <FlexBetween mt="0.5rem">
                <Typography>FastRepair</Typography>
                <CheckBox
                  checked={data.iracing_fastrepair_box === 1 ? true : false}
                />
              </FlexBetween>
              <Typography mb="0.5rem">
                {"FastRepairs remaining: " + data.iracing_fastrepair_remaining}
              </Typography>
              <Divider />
              <Typography mt="0.5rem">
                {"Time lost in Pit: " + data.plost.toFixed(2) + " s"}
              </Typography>
            </CardContent>
          </Card>
        </FlexBetween>
      </Box>
    </Box>
  );
}

export default LiveTelemetry;
