import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import WidgetWrapper from "components/WidgetWrapper";

export function CalenderWidget(eventsinput) {
  const eventsInput = eventsinput.events;
  const events = eventsInput.map((eventsInput) => {
    return {
      title: eventsInput.eventTitle,
      start: eventsInput.eventStart,
      allDay: eventsInput.eventType === "Bday" ? true : false,
      end: eventsInput.eventEnd,
      url: `${process.env.REACT_APP_client_URL}/event/${eventsInput._id}`,
    };
  });

  return (
    <WidgetWrapper>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        timeZone="local"
        locale="de"
        eventContent={renderEventContent}
        eventDisplay={"block"}
        height={"70vh"}
        aspectRatio={2}
      />
    </WidgetWrapper>
  );
}

// a custom render function
function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

export default CalenderWidget;
