import {
  Box,
  Table,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  useTheme,
  Chip,
  TableBody,
  IconButton,
} from "@mui/material";

import Navbar from "scenes/navbar";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

const ResultPage = () => {
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [isAddEvent, setAddEvent] = useState(false);
  const [seriesList, setSeriesList] = useState([]);
  const timeNow = new Date();
  const { palette } = useTheme();
  const [team, setTeam] = useState([]);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [eventOpen, setEventOpen] = useState(false);
  const [seriesEvents, setSeriesEvents] = useState([]);
  const [events, setEvents] = useState([]);

  const getTeam = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/team`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
  };

  const getEvents = async () => {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/event`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    let results = [];
    data.forEach((event) => {
      if (event.results.length > 0) {
        event.results.forEach((result) => {
          results.push({ ...event, ...result });
        });
      }
    });
    setEvents(results);
  };

  useEffect(() => {
    getEvents();
    getTeam();
  }, []);

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box height="100vh" display="flex" flexDirection="column">
          <Navbar />
          <Box
            display="flex"
            flexDirection="row"
            flex="flex-grow"
            height="100%"
          >
            <Sidenavbar />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="scroll"
            >
              {user.role.includes("guest") ? (
                <Typography>Intern Only!</Typography>
              ) : (
                <Box>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Event</TableCell>
                          <TableCell>Serie</TableCell>
                          <TableCell>Datum</TableCell>
                          <TableCell>Team</TableCell>
                          <TableCell>Fahrer</TableCell>
                          <TableCell>Position</TableCell>
                          <TableCell>Kommentar</TableCell>
                          <TableCell>IR-Session</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {events.map((event) => (
                          <TableRow key={event.eventTitel + "_SerieTableHead "}>
                            <TableCell>{event.eventTitle}</TableCell>
                            <TableCell>{event.Series}</TableCell>
                            <TableCell>
                              {new Date(event.eventStart).toLocaleDateString(
                                "de-De"
                              )}
                            </TableCell>
                            <TableCell>{event.teamname}</TableCell>
                            <TableCell>
                              {console.log(event.drivers)}
                              {event.drivers.map((d) => (
                                <Chip
                                  key={d.lastName + "_Driver_" + event._id}
                                  label={d.lastName}
                                  size="small"
                                />
                              ))}
                            </TableCell>
                            <TableCell>{event.placement}</TableCell>
                            <TableCell>{event.comment}</TableCell>
                            <TableCell>
                              <IconButton></IconButton>
                            </TableCell>
                            <TableCell>blabla</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ResultPage;
