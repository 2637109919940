import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import { useEffect, useState } from "react";

function TimingTab({ data }) {
  const Drivers = data.d;
  const Positions = data.idx_pos;
  const [Rows, setRows] = useState([]);
  const { palette } = useTheme();
  const FocusIdx = data.c;
  const FocusDriver = data.d[FocusIdx];

  function prettyOutputLap(input) {
    let minutes = Math.floor(input / 60);
    let secounds = (Math.floor((input - minutes * 60) * 1000) / 1000).toFixed(
      3
    );
    var string = minutes + ":" + ("0" + secounds).slice(-6);
    return string;
  }

  function prettyOutputTime(input) {
    if (input === "--") {
      return "--";
    } else {
      let hours = Math.floor(input / 3600);
      let minutes = Math.floor((input - hours * 3600) / 60);
      let secounds = Math.floor(input - hours * 3600 - minutes * 60);
      var string =
        (hours < 10 ? "0" + hours : hours) +
        ":" +
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (secounds < 10 ? "0" + secounds : secounds);
      return string;
    }
  }

  const TableRowOutput = () => {
    var rows = [];
    for (let i = 1; i < 65; i++) {
      if (Positions[i] != -1) {
        rows.push(Drivers[Positions[i]]);
      }
    }
    setRows(rows);
  };

  function getStripedStyle(classid) {
    if (classid === 1) {
      return { bgcolor: "#77F960" };
    }
    if (classid === 2) {
      return { bgcolor: "#BACCFE" };
    }
    if (classid === 3) {
      return { bgcolor: "#ED82FE" };
    }
    if (classid === 4) {
      return { bgcolor: "#FFFC33" };
    }
  }

  useEffect(() => {
    TableRowOutput();
  }, [data]);

  return (
    <Card>
      {Rows.length ? (
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">+/-</TableCell>
                <TableCell align="center">P</TableCell>
                <TableCell align="center">Class</TableCell>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Gap</TableCell>
                <TableCell align="center">Rel</TableCell>
                <TableCell align="center">Last</TableCell>
                <TableCell align="center">Best</TableCell>
                <TableCell align="center">LC</TableCell>
                <TableCell align="center">Stint</TableCell>
                <TableCell align="center">Pit</TableCell>
                <TableCell align="center">Lane</TableCell>
                <TableCell align="center">Stop</TableCell>
                <TableCell align="center">LP</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Rows.map((r, index) => (
                <TableRow
                  key={"timing_row_" + index}
                  sx={
                    r.track_status === -1
                      ? { bgcolor: palette.neutral.dark }
                      : { bgcolor: palette.neutral.light }
                  }
                  selected={r.num == FocusIdx ? true : false}
                >
                  <TableCell
                    align="center"
                    p="1rem"
                    sx={
                      data.styp === "Race"
                        ? r.cpos - r.scpos > 0
                          ? { color: "#FE0000" }
                          : r.cpos - r.scpos < 0
                          ? { color: "#0FFE00" }
                          : null
                        : null
                    }
                  >
                    {data.styp === "Race"
                      ? r.cpos - r.scpos > 0
                        ? "+" + (r.cpos - r.scpos).toString()
                        : r.cpos - r.scpos < 0
                        ? r.cpos - r.scpos
                        : null
                      : ""}
                  </TableCell>
                  <TableCell align="center">{r.pos}</TableCell>
                  <TableCell align="center">{r.cpos}</TableCell>
                  <TableCell align="center" sx={getStripedStyle(r.classid)}>
                    {r.num}
                  </TableCell>
                  <TableCell>
                    {r.teamracing === 0 ? (
                      <Box>
                        <Typography>{r.tn}</Typography>
                        <Typography>{r.name}</Typography>
                      </Box>
                    ) : (
                      <Box>
                        <Typography>{r.name}</Typography>
                      </Box>
                    )}
                  </TableCell>
                  {data.styp !== "Race" ? (
                    <TableCell align="center">
                      {(r.b - Rows[0].b).toFixed(3)}
                    </TableCell>
                  ) : (
                    <TableCell align="center">{r.cg.toFixed(3)}</TableCell>
                  )}
                  {data.cpos === 1 ? (
                    <TableCell align="center">0</TableCell>
                  ) : (
                    <TableCell align="center">{r.interval}</TableCell>
                  )}
                  <TableCell align="center">{prettyOutputLap(r.l)}</TableCell>
                  <TableCell align="center">{prettyOutputLap(r.b)}</TableCell>
                  <TableCell align="center">{r.lc}</TableCell>
                  <TableCell align="center">{r.sti.toFixed(0)}</TableCell>
                  <TableCell align="center">
                    {r.np === 0 ? "--" : r.np}
                  </TableCell>
                  <TableCell align="center">{r.rt === 0 ? "" : r.rt}</TableCell>
                  <TableCell align="center">{r.st === 0 ? "" : r.st}</TableCell>
                  <TableCell align="center">
                    {Math.floor(r.lc - r.sti) < 0
                      ? ""
                      : Math.floor(r.lc - r.sti)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Card>
  );
}

export default TimingTab;
