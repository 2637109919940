import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage/index.jsx";
import LoginPage from "scenes/loginPage/index.jsx";
import ProfilePage from "scenes/profilePage/index.jsx";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "theme";
import Navbar from "scenes/navbar";
import CalenderPage from "scenes/calenderPage/index.jsx";
import EventListPage from "scenes/EventListPage";
import EventPage from "scenes/EventPage";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SessionsPage from "scenes/SessionsPage";
import SessionPage from "scenes/SessionPage";
import ProfilSettingsPage from "scenes/ProfilSettingPage";
import StintPlanerPage from "scenes/StintplanerPage";
import TeamPage from "scenes/teamPage";
import ExternPage from "scenes/externPage";
import SerienPage from "scenes/SerienPage";
import ResultPage from "scenes/resultPage";

function App() {
  const mode = useSelector((state) => state.auth.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="app">
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<ExternPage />} />
              <Route path="/home" element={<HomePage />} />
              <Route path="/settings" element={<ProfilSettingsPage />} />
              <Route path="/profile/:userId" element={<ProfilePage />} />
              <Route path="/calender" element={<CalenderPage />} />
              <Route path="/event" element={<EventListPage />} />
              <Route path="/event/:eventId" element={<EventPage />} />
              <Route path="/series" element={<SerienPage />} />
              <Route path="navbar" element={<Navbar />} />
              <Route path="/sessions" element={<SessionsPage />} />
              <Route path="/team" element={<TeamPage />} />
              <Route path="/results" element={<ResultPage />} />
              <Route
                path="/stintplaner/:planerId"
                element={<StintPlanerPage />}
              />
              <Route
                path="/sessions/:sessionId/:teamName"
                element={<SessionPage />}
              />
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </div>
    </LocalizationProvider>
  );
}

export default App;
