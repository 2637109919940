import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  CardMedia,
  DialogTitle,
  Fab,
  InputLabel,
  FormControl,
  Tab,
  Tabs,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import EventSmallWidget from "scenes/widgets/EventSmallWidget";
import AddIcon from "@mui/icons-material/Add";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FlexBetween from "components/FlexBetween";
import { useEffect, useState } from "react";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";
import { useParams } from "react-router-dom";
import Friend from "components/Friend";
import { setFriends, setEvents } from "state";
import ParticipentsWidget from "scenes/widgets/ParticipentsWidget";
import FlexBetweenTop from "components/FlexBetweenTop";
import { MuiColorInput } from "mui-color-input";
import iracingSelects from "components/iracingSelects";
import ErrorHandler from "components/ErrorHandler";
import { setErrorMessage, setErrorState } from "state/error";
import PropTypes from "prop-types";
import Availibility from "./Availibitly";
import PostWidget from "./EventPost";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import Wish from "./Wisch";
import EditEvent from "./EditEvent";
import { Container } from "@mui/system";
import DiscordBot from "./DiscordBot";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container maxWidth="100%">
          <Box>{children}</Box>
        </Container>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const EventPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1250px)");
  const { eventId } = useParams();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const loggedInUserId = useSelector((state) => state.auth.user._id);
  const { _id, picturePath } = useSelector((state) => state.auth.user);
  // const [userInfo, setUserInfo] = useState();
  const [event, setEvent] = useState();
  const userInfo = useSelector((state) => state.auth.user.friends);
  const [selectedCar, setSelectedCar] = useState("none");
  const [selectedClass, setSelectedClass] = useState("none");
  const user = useSelector((state) => state.auth.user);

  const [addColor, setAddColor] = useState("#ffffff");
  const AddColorHandleChange = (color) => {
    setAddColor(color);
  };

  const dataFetch = async () => {
    const data = await (
      await fetch(`${process.env.REACT_APP_SERVER_URL}/event/${eventId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
    ).json();
    setEvent(data);
  };

  const getUser = async () => {
    const userListResponse = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${eventId}/users`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await userListResponse.json();
    dispatch(setFriends({ friends: data }));
  };

  const handleNewTeam = async () => {
    const newTeam = {
      teamname: document.getElementById("newTeamInput_name").value,
      color: document.getElementById("newTeamInput_color").value,
      class: document.getElementById("newTeamInput_class").value,
      car: selectedCar,
      stintplaner: document.getElementById("newTeamInput_stintplaner").value,
      drivers: [],
    };
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/addteam`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId, newTeam: newTeam }),
      }
    );
    const resteams = await response.json();
    setEvent(resteams);
  };

  const handleRemoveTeam = async (team) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/removeteam/${team}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resteams = await response.json();
    setEvent(resteams);
  };

  const handleEditTeam = async () => {
    const newTeam = editValues;
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/editteam/${selectedTeam.teamname}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId, newTeam: newTeam }),
      }
    );
    setShowEditBackdrop(false);
    const resteams = await response.json();
    if ("error" in resteams) {
      dispatch(setErrorState());
      dispatch(setErrorMessage({ error: resteams.error }));
    } else {
      setEvent(resteams);
    }
  };

  const handleResultTeam = async () => {
    const newTeam = resultValues;
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/resultteam/${selectedTeam.teamname}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId, newTeam: newTeam }),
      }
    );
    setShowResultBackdrop(false);
    const resteams = await response.json();
    if ("error" in resteams) {
      dispatch(setErrorState());
      dispatch(setErrorMessage({ error: resteams.error }));
    } else {
      setEvent(resteams);
    }
  };

  const handleAddtoTeam = async (newid) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/team/${selectedTeam}/${newid}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resteams = await response.json();
    setEvent(resteams);
  };

  const handleRemoveFromTeam = async (team, newid) => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${event._id}/team/${team}/${newid}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const resteams = await response.json();
    setEvent(resteams);
  };

  const setEventFromChild = (event) => {
    setEvent(event);
  };

  const [activeTab, setActiveTab] = useState(0);
  const handleChangeTabs = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [showEditBackdrop, setShowEditBackdrop] = useState(false);
  const [editValues, setEditValues] = useState(null);

  const [showResultBackdrop, setShowResultBackdrop] = useState(false);
  const [resultValues, setResultValues] = useState(null);

  const handleEditOpenBackdrop = (team) => {
    const newt = team;
    setSelectedTeam(newt);
    setEditValues(newt);
    setShowEditBackdrop(true);
  };

  const handleEditCloseBackdrop = () => {
    const newt = null;
    setShowEditBackdrop(false);
    setEditValues(newt);
    setSelectedTeam(newt);
  };

  const handleOpenBackdrop = (team) => {
    const newt = team;
    setSelectedTeam(newt);
    setShowBackdrop(true);
  };

  const handleCloseBackdrop = () => {
    const newt = null;
    setShowBackdrop(false);
    setSelectedTeam(newt);
  };

  const handleResultOpenBackdrop = (team) => {
    const newt = team;
    setSelectedTeam(newt);
    setResultValues(newt);
    setShowResultBackdrop(true);
  };

  const handleResultCloseBackdrop = () => {
    const newt = null;
    setShowResultBackdrop(false);
    setSelectedTeam(newt);
  };

  useEffect(() => {
    getUser();
    dataFetch();
  }, []);

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box>
          <ErrorHandler />
          <Navbar />
          <Box
            display="flex"
            flexDirection={isNonMobileScreens ? "row" : "column"}
            flex="flex-grow"
            height="100%"
          >
            {isNonMobileScreens && <Sidenavbar />}
            <Box
              width="100%"
              padding="2rem 6%"
              display={isNonMobileScreens ? "flex" : "block"}
              flexDirection="column"
              gap="0.5rem"
              justifyContent="space-between"
              overflow="scroll"
            >
              <Box
                width="100%"
                display={isNonMobileScreens ? "flex" : "block"}
                flexDirection="column"
                gap="1rem"
                justifyContent="space-between"
              >
                {event === undefined ? (
                  <Box></Box>
                ) : (
                  <Box
                    width="100%"
                    display={isNonMobileScreens ? "flex" : "block"}
                    flexDirection="column"
                    gap="1rem"
                    justifyContent="space-between"
                  >
                    <EventSmallWidget event={event} />

                    <Box
                      flexBasis={isNonMobileScreens ? "42%" : undefined}
                      mt={isNonMobileScreens ? undefined : "2rem"}
                    >
                      <FlexBetween
                        width="100%"
                        display="flex"
                        flexDirection={isNonMobileScreens ? "row" : "column"}
                        justifyContent="center"
                        alignItems="top"
                        gap="1rem"
                        flexBasis="1"
                      >
                        <Box
                          gap="1rem"
                          width="100%"
                          display="flex"
                          flexDirection={isNonMobileScreens ? "row" : "column"}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-start"
                            alignItems="left"
                            gap="1rem"
                            flexBasis="1"
                          >
                            <ParticipentsWidget
                              key={"Zugesagt"}
                              type={"Zugesagt"}
                              userList={
                                event === undefined ? {} : event.eventYes
                              }
                            />
                            <ParticipentsWidget
                              key={"Vielleicht"}
                              type={"Vielleicht"}
                              userList={
                                event === undefined ? {} : event.eventMaybe
                              }
                            />
                            <ParticipentsWidget
                              key={"Abgesagt"}
                              type={"Abgesagt"}
                              userList={
                                event === undefined ? {} : event.eventNo
                              }
                            />
                          </Box>
                          <Box>
                            <Box>
                              <Tabs
                                value={activeTab}
                                onChange={handleChangeTabs}
                              >
                                <Tab label="Teams" />
                                <Tab label="Verfügbarkeiten" />
                                <Tab label="Kommentare" />
                                <Tab label="Wünsche" />
                                <Tab label="Event" />
                                <Tab label="Discord" />
                              </Tabs>
                            </Box>
                            <CustomTabPanel value={activeTab} index={0}>
                              <FlexBetweenTop
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                gap="1rem"
                                flexBasis="2"
                                flexWrap="wrap"
                              >
                                {"teams" in event ? (
                                  <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    gap="1rem"
                                    flexBasis="2"
                                    flexWrap="wrap"
                                  >
                                    {event.teams.map((team) => (
                                      <Box
                                        display="flex"
                                        width="350px"
                                        key={"box_team_" + team}
                                      >
                                        <Card
                                          sx={{
                                            width: "100%",
                                          }}
                                          key={"card_team_" + team}
                                        >
                                          <CardHeader
                                            avatar={
                                              <Avatar
                                                sx={{ bgcolor: team.color }}
                                                aria-label="recipe"
                                              >
                                                {team.teamname[0]}
                                              </Avatar>
                                            }
                                            title={team.teamname}
                                            subheader={
                                              team.class +
                                              " | " +
                                              iracingSelects[team.car].name
                                            }
                                          ></CardHeader>
                                          <CardMedia
                                            component="img"
                                            height="194"
                                            image={
                                              "../assets/cars/" +
                                              iracingSelects[team.car].img
                                            }
                                            alt={team.car}
                                          />
                                          <CardContent>
                                            {team.drivers.length > 0 ? (
                                              team.drivers.map((user) => (
                                                <FlexBetween
                                                  gap="0.5rem"
                                                  mt="1rem"
                                                >
                                                  <Friend
                                                    id={user._id + team}
                                                    key={user._id}
                                                    friendId={user._id}
                                                  />
                                                  <Button
                                                    onClick={() => {
                                                      handleRemoveFromTeam(
                                                        team.teamname,
                                                        user._id
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </Button>
                                                </FlexBetween>
                                              ))
                                            ) : (
                                              <Typography>
                                                Keiner hier
                                              </Typography>
                                            )}
                                          </CardContent>
                                          <CardActions>
                                            <FlexBetween>
                                              <Button
                                                onClick={() =>
                                                  window.open(
                                                    team.stintplaner,
                                                    "_blank"
                                                  )
                                                }
                                              >
                                                Stintplaner
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  setSelectedTeam(
                                                    team.teamname
                                                  );
                                                  handleOpenBackdrop(
                                                    team.teamname
                                                  );
                                                }}
                                              >
                                                Add Driver
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  handleEditOpenBackdrop(team);
                                                }}
                                              >
                                                Edit
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  handleResultOpenBackdrop(
                                                    team
                                                  );
                                                }}
                                              >
                                                Result
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  handleRemoveTeam(
                                                    team.teamname
                                                  );
                                                }}
                                              >
                                                Delete
                                              </Button>
                                            </FlexBetween>
                                          </CardActions>
                                        </Card>
                                      </Box>
                                    ))}
                                    <Dialog
                                      open={showBackdrop}
                                      onClose={handleCloseBackdrop}
                                      fullWidth
                                      maxWidth="md"
                                    >
                                      <DialogTitle>Add Driver</DialogTitle>
                                      <DialogContent>
                                        {Object.keys(event.eventYes).length
                                          ? Object.keys(event.eventYes).map(
                                              (user) => (
                                                <FlexBetween
                                                  key={"flex_yes_" + user}
                                                >
                                                  <Friend
                                                    key={
                                                      event.eventYes[user]._id
                                                    }
                                                    friendId={
                                                      event.eventYes[user]._id
                                                    }
                                                  />
                                                  <Fab
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                      const newid =
                                                        event.eventYes[user]
                                                          ._id;
                                                      handleAddtoTeam(newid);
                                                    }}
                                                  >
                                                    <AddIcon />
                                                  </Fab>
                                                </FlexBetween>
                                              )
                                            )
                                          : null}
                                        {Object.keys(event.eventMaybe).length
                                          ? Object.keys(event.eventMaybe).map(
                                              (user) => (
                                                <FlexBetween
                                                  key={"flex_maybe_" + user}
                                                >
                                                  <Friend
                                                    key={
                                                      event.eventMaybe[user]._id
                                                    }
                                                    friendId={
                                                      event.eventMaybe[user]._id
                                                    }
                                                  />
                                                  <Fab
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                      const newid =
                                                        event.eventMaybe[user]
                                                          ._id;
                                                      handleAddtoTeam(newid);
                                                    }}
                                                  >
                                                    <AddIcon />
                                                  </Fab>
                                                </FlexBetween>
                                              )
                                            )
                                          : null}
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={handleCloseBackdrop}
                                          color="primary"
                                        >
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    <Dialog
                                      open={showEditBackdrop}
                                      onClose={handleEditCloseBackdrop}
                                      fullWidths
                                      maxWidth="md"
                                    >
                                      <DialogTitle>
                                        {editValues === null
                                          ? ""
                                          : editValues.teamname}
                                      </DialogTitle>
                                      <DialogContent>
                                        <TextField
                                          id="editTeamInput_name"
                                          label="Teamname"
                                          variant="outlined"
                                          value={
                                            editValues
                                              ? editValues.teamname
                                              : ""
                                          }
                                          onChange={(event) => {
                                            const eteam = editValues
                                              ? { ...editValues }
                                              : {};
                                            eteam.teamname = event.target.value;
                                            setEditValues(eteam);
                                          }}
                                        />
                                        <MuiColorInput
                                          id="editTeamInput_color"
                                          value={
                                            editValues === null
                                              ? "#ffffff"
                                              : editValues.color
                                          }
                                          onChange={AddColorHandleChange}
                                        />

                                        <TextField
                                          id="editTeamInput_class"
                                          label="Klasse"
                                          variant="outlined"
                                          value={
                                            editValues === null
                                              ? ""
                                              : editValues.class
                                          }
                                          onChange={(event) => {
                                            const eteam = editValues
                                              ? { ...editValues }
                                              : {};
                                            eteam.class = event.target.value;
                                            setEditValues(eteam);
                                          }}
                                        />
                                        <FormControl
                                          sx={{ m: 1, minWidth: 120 }}
                                        >
                                          <InputLabel id="selectedCarLabel">
                                            Auto
                                          </InputLabel>
                                          <Select
                                            id="editTeamInput_car"
                                            label="Auto"
                                            labelId="selectedCarLabel"
                                            value={
                                              editValues === null
                                                ? ""
                                                : editValues.car
                                            }
                                            onChange={(e) =>
                                              setSelectedCar(e.target.value)
                                            }
                                          >
                                            {Object.keys(iracingSelects).map(
                                              (car) => (
                                                <MenuItem
                                                  key={
                                                    "selectedCar " +
                                                    iracingSelects[car].id
                                                  }
                                                  value={iracingSelects[car].id}
                                                >
                                                  {iracingSelects[car].name}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </FormControl>
                                        <TextField
                                          id="editTeamInput_stintplaner"
                                          label="Stintplaner Link"
                                          variant="outlined"
                                          value={
                                            editValues === null
                                              ? ""
                                              : editValues.stintplaner
                                          }
                                          onChange={(event) => {
                                            const eteam = editValues
                                              ? { ...editValues }
                                              : {};
                                            eteam.stintplaner =
                                              event.target.value;
                                            setEditValues(eteam);
                                          }}
                                        />
                                        <Button
                                          variant="contained"
                                          endIcon={<AddOutlinedIcon />}
                                          onClick={handleEditTeam}
                                          sx={{ width: "100%" }}
                                        >
                                          Edit Team
                                        </Button>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={handleEditCloseBackdrop}
                                          color="primary"
                                        >
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                    <Dialog
                                      open={showResultBackdrop}
                                      onClose={handleResultCloseBackdrop}
                                      fullWidths
                                      maxWidth="md"
                                    >
                                      <DialogTitle>
                                        {selectedTeam === null
                                          ? ""
                                          : "Result for " +
                                            selectedTeam.teamname}
                                      </DialogTitle>
                                      <DialogContent>
                                        <TextField
                                          id="resultTeamInput_placement"
                                          label="Platzierung"
                                          variant="outlined"
                                          value={
                                            resultValues
                                              ? resultValues.placement
                                              : ""
                                          }
                                          onChange={(event) => {
                                            const eteam = resultValues
                                              ? { ...resultValues }
                                              : {};
                                            eteam.placement =
                                              event.target.value;
                                            setResultValues(eteam);
                                          }}
                                        />
                                        <TextField
                                          id="resultTeamInput_comment"
                                          label="Kommentar"
                                          variant="outlined"
                                          value={
                                            resultValues
                                              ? resultValues.comment
                                              : ""
                                          }
                                          onChange={(event) => {
                                            const eteam = resultValues
                                              ? { ...resultValues }
                                              : {};
                                            eteam.comment = event.target.value;
                                            setResultValues(eteam);
                                          }}
                                        />
                                        <TextField
                                          id="resultTeamInput_sessionId"
                                          label="Session Id"
                                          variant="outlined"
                                          value={
                                            resultValues
                                              ? resultValues.sessionid
                                              : ""
                                          }
                                          onChange={(event) => {
                                            const eteam = resultValues
                                              ? { ...resultValues }
                                              : {};
                                            eteam.sessionid =
                                              event.target.value;
                                            setResultValues(eteam);
                                          }}
                                        />
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          variant="contained"
                                          endIcon={<AddOutlinedIcon />}
                                          onClick={handleResultTeam}
                                          sx={{ width: "100%" }}
                                        >
                                          Save
                                        </Button>
                                        <Button
                                          onClick={handleResultCloseBackdrop}
                                          color="primary"
                                        >
                                          Close
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </Box>
                                ) : null}
                                <Box>
                                  <Card>
                                    <CardHeader title="Team hinzufügen" />
                                    <CardContent>
                                      <FlexBetween>
                                        <TextField
                                          id="newTeamInput_name"
                                          label="Teamname"
                                          variant="outlined"
                                        />
                                        <MuiColorInput
                                          id="newTeamInput_color"
                                          value={addColor}
                                          onChange={AddColorHandleChange}
                                        />
                                      </FlexBetween>
                                      <FlexBetween>
                                        <TextField
                                          id="newTeamInput_class"
                                          label="Klasse"
                                          variant="outlined"
                                        />
                                        <FormControl
                                          sx={{ m: 1, minWidth: 120 }}
                                        >
                                          <InputLabel id="selectedCarLabel">
                                            Auto
                                          </InputLabel>
                                          <Select
                                            id="newTeamInput_car"
                                            label="Auto"
                                            labelId="selectedCarLabel"
                                            value={selectedCar}
                                            onChange={(e) =>
                                              setSelectedCar(e.target.value)
                                            }
                                          >
                                            {Object.keys(iracingSelects).map(
                                              (car) => (
                                                <MenuItem
                                                  key={
                                                    "selectedCar " +
                                                    iracingSelects[car].id
                                                  }
                                                  value={iracingSelects[car].id}
                                                >
                                                  {iracingSelects[car].name}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </FormControl>
                                      </FlexBetween>
                                      <TextField
                                        id="newTeamInput_stintplaner"
                                        label="Stintplaner Link"
                                        variant="outlined"
                                      />
                                    </CardContent>
                                    <CardActions>
                                      <Button
                                        variant="contained"
                                        endIcon={<AddOutlinedIcon />}
                                        onClick={handleNewTeam}
                                        sx={{ width: "100%" }}
                                      >
                                        Add Team
                                      </Button>
                                    </CardActions>
                                  </Card>
                                </Box>
                              </FlexBetweenTop>
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTab} index={1}>
                              {event === undefined ? null : (
                                <Availibility event={event} />
                              )}
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTab} index={2}>
                              {event === undefined ? null : (
                                <>
                                  {event.eventComments.length > 0
                                    ? event.eventComments.map(
                                        (
                                          {
                                            userId,
                                            firstName,
                                            lastName,
                                            description,
                                            location,
                                            picturePath,
                                            userPicturePath,
                                            likes,
                                            comments,
                                          },
                                          index
                                        ) => (
                                          <PostWidget
                                            key={"comment" + index}
                                            postId={index}
                                            postUserId={userId}
                                            name={`${firstName} ${lastName}`}
                                            description={description}
                                            location={location}
                                            picturePath={picturePath}
                                            userPicturePath={userPicturePath}
                                            likes={likes}
                                            comments={comments}
                                          />
                                        )
                                      )
                                    : null}
                                  <MyPostWidget
                                    picturePath={picturePath}
                                    setEvent={setEventFromChild}
                                    eventId={eventId}
                                  />
                                </>
                              )}
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTab} index={3}>
                              <Wish event={event} />
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTab} index={4}>
                              <EditEvent event={event} />
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTab} index={5}>
                              <DiscordBot event={event} />
                            </CustomTabPanel>
                          </Box>
                        </Box>
                      </FlexBetween>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EventPage;
