import {
  Box,
  FormControlLabel,
  Switch,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

function StintPlanerPage() {
  const defaultInput = {
    raceLengthHours: 2,
    raceLengthMinute: 0,
    pitTime: 0,
    pitFuel: 100,
    pitTire: 30,
    pitType: true,
  };
  const [input, setInput] = useState(defaultInput);
  const [racedata, setRacedata] = useState(null);

  function RaceDataCalc(input) {
    const data = {
      raceLength: input.raceLengthHours * 60 * 60 + input.raceLengthMinute * 60,
      stints: [],
    };
    let remainingTime = data.raceLength;
    if (data.stints.length == 0) {
      let stintNr = 1;
      while (remainingTime > 0) {
        let stint = {
          Driver: "-",
          Type: 0,
          Stint: stintNr,
          Start: 0,
          End: 0,
          Duration: 0,
          Laps: 0,
        };
        data.stints.append(stint);
        remainingTime -= stint.Duration;
      }
    }
    setRacedata(data);
  }

  const handleChangePitType = () => {
    setInput((prevInput) => ({
      ...prevInput,
      pitType: !prevInput.pitType,
    }));
  };

  return (
    <Box>
      <Box>
        <Box>
          <Typography>Rennlänge</Typography>
          <TextField
            required
            id="raceLengthHours"
            label="Stunden"
            value={input.raceLengthHours}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            id="raceLengthMinute"
            label="Minuten"
            value={input.raceLengthMinute}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        <Box>
          <Typography>Boxeninfos</Typography>
          <TextField
            required
            id="pitTime"
            label="Boxenstopzeit in s"
            value={input.pitTime}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            id="pitFuel"
            label="Max Tankinhalt"
            value={input.pitFuel}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            required
            id="pitTire"
            label="Reifenwechselzeit in s"
            value={input.pitTire}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControlLabel
            control={
              <Switch checked={input.pitType} onChange={handleChangePitType} />
            }
            label="Reifen und Tanken gleichzeitig"
          />
        </Box>
      </Box>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Stint</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>Ende</TableCell>
                <TableCell>Dauer</TableCell>
                <TableCell>Runden</TableCell>
                <TableCell>Fahrer</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default StintPlanerPage;
