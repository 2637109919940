import { Box, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import WidgetWrapper from "components/WidgetWrapper";
import EventSmallWidget from "scenes/widgets/EventSmallWidget";
import { useEffect, useState } from "react";
import CalenderWidget from "scenes/widgets/CalenderWidget";
import Sidenavbar from "scenes/sidenavbar/sidenavbar";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.auth.user);
  const [events, setEvents] = useState([]);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);

  const getEvents = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/users/${_id}/events`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    setEvents(data);
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Box overflow="hidden">
      {user.role.includes("guest") ? (
        <Typography>Intern Only!</Typography>
      ) : (
        <Box height="100vh" display="flex" flexDirection="column">
          <Navbar />
          <Box
            display="flex"
            flexDirection="row"
            flex="flex-grow"
            height="100%"
          >
            <Sidenavbar />
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              overflow="scroll"
            >
              <Box
                width="100%"
                padding="2rem 6%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="0.5rem"
                justifyContent="space-between"
              >
                <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
                  <UserWidget userId={_id} picturePath={picturePath} />
                </Box>
                <Box
                  flexBasis={isNonMobileScreens ? "42%" : undefined}
                  mt={isNonMobileScreens ? undefined : "2rem"}
                >
                  <CalenderWidget events={events}></CalenderWidget>
                  <Typography>Deine nächsten Events</Typography>
                  {events.length
                    ? events.map((event) => (
                        <Box width="100%">
                          <EventSmallWidget key={event._id} event={event} />
                        </Box>
                      ))
                    : null}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default HomePage;
